<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1 v-if="this.profile.status && this.profile.status.status_name">Account {{
                    this.profile.status.status_name }}</h1>
                <p>Your account status is currently inactive</p>
            </div>

            <div class="info-container container"
                v-if="this.profile.status && this.profile.status.status_name && this.profile.status.status_desc">
                <h3><v-icon class="mr-3">{{ 'mdi-lock' }}</v-icon> Account {{ this.profile.status.status_name }}</h3>
                <p>{{ this.profile.status.status_desc }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';

export default {
    name: 'AccountInactiveView',
    components: {
        TopNav,
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    mounted() {
        this.getProfile()
            .then(result => {
                this.$router.push({ path: '/' });
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    },
    methods: {
        ...mapActions(useAuthStore, ['getProfile']),
    }
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
form {
    width: 60%;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    white-space: pre-line;
}

.info-container h3 {
    color: var(--primary-blue);
}
</style>
