import { defineStore } from "pinia";

export const useLoadingStore = defineStore("LoadingStore", {
    // State
    state: () => {
        return {
            isLoading: false,
        }
    },
    // Getters
    getters: {
        loading: (state) => state.isLoading,
    },
    // Actions
    actions: {
        setLoading(type) {
            this.isLoading = type
        }
    }
})

