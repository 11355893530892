<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Billings</h1>
                <p>Dashboard > Billings</p>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('pending')"
                                :class="{ 'button-1': currentFilter === 'pending' }">Pending</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('paid')"
                                :class="{ 'button-1': currentFilter === 'paid' }">Paid</a>
                        </li>
                    </ul>
                    <a class="button-2" @click.prevent="this.$router.push('/billings/preview')">
                        Preview Billing
                    </a>
                </div>

                <div class="table-container">
                    <table v-if="data.length > 0">
                        <thead>
                            <tr>
                                <th class="td-start">No.</th>
                                <th>Driver / Tour Guide</th>
                                <th>Guest Name</th>
                                <th>No. of Location(s)</th>
                                <th>Request On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(request, index) in data">
                                <td class="td-start">{{ index + 1 }}.</td>
                                <td class="users" v-if="request.transfer_type_special_request != null">
                                    <div class="user">
                                        <div class="image-container">
                                            <Image :imagePath="request.transfer_type_special_request.user.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ request.transfer_type_special_request.user.first_name + ' ' +
                                    request.transfer_type_special_request.user.last_name }}</p>
                                    </div>
                                </td>
                                <td class="user" v-else>
                                    Driver have been deleted
                                </td>
                                <td class="users">
                                    <div class="tourist user" v-for="guest in request.distinctGuests">
                                        <div class="image-container">
                                            <Image :imagePath="guest.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ guest.first_name + ' ' + guest.last_name }}</p>
                                    </div>
                                </td>
                                <td>{{ request.details.length }}</td>
                                <td>{{ formatDateTimeFull(request.created_at) }}</td>
                                <td>
                                    <a href="" class="button-3"
                                        @click.prevent="this.$router.push('/requests/' + request.uuid)">View</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="no-data" v-else>
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { httpGet } from '@/services/http';

export default {
    name: 'BillingsView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            orgData: [],
            data: [],
            currentFilter: 'pending', // 'pending' or 'approved'
        };
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            httpGet('/api/v1/travel_agents/jobs/assigns')
                .then(response => {
                    this.orgData = response.data.data;
                    this.orgData.forEach(request => {
                        request.distinctGuests = this.consolidateGuestNames(request.details);
                        console.log(request.distinctGuests)
                    });
                    this.changeFilter(this.currentFilter);
                })
                .catch(error => {
                    console.error('Failed to load data:', error);
                });
        },
        consolidateGuestNames(details) {
            const guests = new Set();
            details.forEach(detail => {
                // Assuming each detail has a guest information
                guests.add(JSON.stringify(detail.transfer.job.tourist));
            });
            return [...guests].map((item) => JSON.parse(item))
        },
        changeFilter(input) {
            this.currentFilter = input
            if (this.currentFilter == 'pending') {
                this.data = this.orgData.filter((a) => a.status == 'Pending')
            } else if (this.currentFilter == 'approved') {
                this.data = this.orgData.filter((a) => a.status == 'Approved')
            } else {
                this.data = []
            }
            console.log(JSON.stringify(this.data))
        },
        formatDate(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },
        formatDateTimeFull(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();

            // Extract hours and minutes for time formatting in 24-hour format
            const hours = d.getHours();
            const minutes = d.getMinutes();

            // Ensuring two-digit hours and minutes
            const hoursFormatted = hours < 10 ? '0' + hours : hours;
            const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;

            // Format the date and time string
            return `${day} ${month} ${year} ${hoursFormatted}:${minutesFormatted}`;
        },

    },
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
