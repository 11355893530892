// stores/chatStore.js
import { defineStore } from 'pinia';
import { httpGet, httpPost } from '@/services/http';

export const useChatStore = defineStore('chatStore', {
    state: () => ({
        chatSummaries: [],
        dnt: [],
        messageText: '',
        user: {},
        messages: [],
        id: '',
    }),
    actions: {
        async fetchChats() {
            try {
                const response = await httpGet('/api/v1/travel_agents/chats');
                this.chatSummaries = this.processChatData(response.data.data);
                if (this.id) {
                    this.fetchMessages(this.id);
                }
            } catch (error) {
                console.error("Failed to fetch chats", error);
            }
        },
        async fetchUser(id) {
            try {
                const response = await httpGet(`/api/v1/travel_agents/users/detail?id=${id}`);
                this.user = response.data.data;
                this.fetchMessages(id); // Fetch messages when fetching user details
            } catch (error) {
                console.error("Failed to fetch user", error);
            }
        },
        async fetchMessages(id) {
            try {
                this.id = id
                const response = await httpGet(`/api/v1/travel_agents/chats`);
                this.messages = response.data.data.filter(
                    (message) => message.from.uuid === id || message.to.uuid === id
                );
            } catch (error) {
                console.error("Failed to fetch messages", error);
            }
        },
        async searchUsers(query) {
            if (!query) {
                this.dnt = [];
                return;
            }

            try {
                const response = await httpGet('/api/v1/travel_agents/users/search?search=' + query);
                if (response.data.data) {
                    this.dnt = response.data.data.filter(a => a.role.role_slug === "driver" || a.role.role_slug === "tour_guide");
                    this.dnt.forEach(a => {
                        a.full_name = `${a.first_name} ${a.last_name} (${a.role.role_name})`;
                    });
                } else {
                    throw new Error('Failed to fetch user');
                }
            } catch (error) {
                console.error('Error:', error);
                this.dnt = [];
            }
        },
        async sendMessage(userId) {
            if (!this.messageText) return;

            let data = new FormData();
            data.append('id', userId);
            data.append('chat_type_slug', 'text');
            data.append('content', this.messageText);

            const config = {
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                await httpPost('/api/v1/travel_agents/chats/create', data, null, config);
                this.messageText = '';
                this.fetchMessages(userId); // Refresh messages after sending a new one
            } catch (error) {
                console.error('Failed to send message', error);
            }
        },
        processChatData(data) {
            const users = {};
            const currentUserId = JSON.parse(localStorage.getItem('profile')).id;

            data.forEach(chat => {
                const fromUser = chat.from;
                const toUser = chat.to;

                if (!users[fromUser.uuid]) {
                    users[fromUser.uuid] = {
                        userId: fromUser.uuid,
                        userName: `${fromUser.first_name} ${fromUser.last_name}`,
                        latestMessage: chat.content,
                        imagePath: fromUser.image_path,
                        createdAt: chat.created_at,
                    };
                } else {
                    if (new Date(users[fromUser.uuid].createdAt) < new Date(chat.created_at)) {
                        users[fromUser.uuid].latestMessage = chat.content;
                        users[fromUser.uuid].createdAt = chat.created_at;
                    }
                }

                if (!users[toUser.uuid]) {
                    users[toUser.uuid] = {
                        userId: toUser.uuid,
                        userName: `${toUser.first_name} ${toUser.last_name}`,
                        latestMessage: chat.content,
                        imagePath: toUser.image_path,
                        createdAt: chat.created_at,
                    };
                } else {
                    if (new Date(users[toUser.uuid].createdAt) < new Date(chat.created_at)) {
                        users[toUser.uuid].latestMessage = chat.content;
                        users[toUser.uuid].createdAt = chat.created_at;
                    }
                }
            });

            return Object.values(users)
                .filter(user => user.userId !== currentUserId)
                .map(user => ({
                    userId: user.userId,
                    userName: user.userName,
                    latestMessage: user.latestMessage,
                    imagePath: user.imagePath
                }));
        }
    },
});
