<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Request Detail</h1>
                <p>Dashboard > Requests > Detail
                <div href="" class="button-1">{{ data.status }}</div>
                </p>
            </div>

            <div class="requested"
                v-if="data && data.transfer_type_special_request && data.transfer_type_special_request.user">
                <div class="user-details container">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="data.transfer_type_special_request.user.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">{{ data.transfer_type_special_request.user.first_name + ' ' +
                    data.transfer_type_special_request.user.last_name }} ({{
                    data.transfer_type_special_request.user.role.role_name }})
                            </h1>
                            <p>{{ data.transfer_type_special_request.user.contact_number }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <p>Filter By: </p>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('date')"
                                :class="{ 'button-1': currentFilter === 'date' }">Date</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('guest')"
                                :class="{ 'button-1': currentFilter === 'guest' }">Guest</a>
                        </li>
                    </ul>
                    <div class="buttons">
                        <!-- <a href="" class="button-3" @click.prevent="deleteRequest">Delete Request</a> -->
                    </div>
                </div>


                <div class="filter-by-user" v-if="currentFilter === 'guest'">
                    <div class="each-user" v-for="(group, index) in groupedData" :key="index">
                        <div class="user-details container">
                            <div v-if="group.tourist">
                                <div class="user">
                                    <div class="image-container">
                                        <Image :imagePath="group.tourist.image_path"></Image>
                                    </div>
                                    <div class="details">
                                        <p>{{ group.tourist.first_name + ' ' + group.tourist.last_name }}</p>
                                        <p>{{ group.tourist.contact_number }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p>Loading...</p>
                            </div>
                        </div>

                        <div class="transfer-container">
                            <div v-for="(date, index) in group.dates" :key="index">
                                <div class="transfer-header">
                                    <h3>{{ formatDate(date.date) }}</h3>
                                </div>
                                <div v-for="(transfer, index) in date.transfers" :key="index" class="transfer-detail">
                                    <div>
                                        <div class="time">{{ formatTime(transfer.pick_up_time) }}</div>
                                        <div class="transfer-type">{{ transfer.transfer_type.type_name }}</div>
                                        <div class="special-slug">
                                            ({{ transfer.transfer_type_special.transfer_type_special_slug }})
                                        </div>
                                    </div>
                                    <div class="line-indicator"></div>
                                    <div class="location-details">
                                        <div class="location-info">
                                            <div class="location">
                                                <h3>{{ transfer.from.main_text }}</h3>
                                                <div>Remarks: {{ transfer.remarks || 'N/A' }}</div>
                                            </div>
                                            <div class="location-path">
                                                <div class="line"></div>
                                                <div class="path-icon">
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                                        viewBox="0 0 24 24">
                                                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                                            viewBox="0 0 24 24">
                                                            <title>steering_wheel_line</title>
                                                            <g id="steering_wheel_line" fill='none' fill-rule='evenodd'>
                                                                <path
                                                                    d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                                                <path fill='#09244BFF'
                                                                    d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2ZM8.313 14.781c-1.044-.835-2.46-1.158-4.108-.972a8.01 8.01 0 0 0 6.254 6.043c-.193-2.625-1.056-4.2-2.146-5.07Zm7.374 0c-1.09.872-1.953 2.446-2.146 5.07a8.01 8.01 0 0 0 6.253-6.042c-1.647-.186-3.063.137-4.107.972ZM12 10c-.95 0-1.732.37-2.306.778l-.498.347c-.524.355-1.126.72-1.801.96a6.435 6.435 0 0 1 2.167 1.134c1.124.9 1.953 2.187 2.438 3.859.485-1.672 1.314-2.96 2.438-3.859a6.434 6.434 0 0 1 2.167-1.133c-.675-.241-1.277-.606-1.801-.961l-.498-.347C13.732 10.37 12.95 10 12 10Zm0-6a8.003 8.003 0 0 0-7.862 6.513l-.043.248 2.21-.442c.582-.116 1.135-.423 1.753-.84l.477-.332C9.332 8.581 10.513 8 12 8c1.388 0 2.509.506 3.3 1.034l.642.445c.54.365 1.032.645 1.536.788l.217.052 2.21.442A8.002 8.002 0 0 0 12 4Z' />
                                                            </g>
                                                        </svg>
                                                    </svg>
                                                </div>
                                                <div class="line"></div>
                                            </div>
                                            <div class="location">
                                                <h3>{{ transfer.to.main_text }}</h3>
                                                <div>Tickets: {{ transfer.tickets.length }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="transfer-container" v-else>
                    <div v-if="groupedByDate && groupedByDate.length > 0" v-for="(group, index) in groupedByDate"
                        :key="index">
                        <div class="transfer-header">
                            <h3>{{ formatDate(group.date) }}</h3>
                        </div>
                        <div v-for="(transfer, index) in group.transfers" :key="index" class="transfer-detail">
                            <div>
                                <div class="time">{{ formatTime(transfer.pick_up_time) }}</div>
                                <div class="transfer-type">{{ transfer.transfer_type.type_name }}</div>
                                <div class="special-slug">({{ transfer.transfer_type_special.transfer_type_special_slug
                                    }})
                                </div>
                            </div>
                            <div class="line-indicator"></div>
                            <div class="location-details">
                                <div class="user">
                                    <div class="image-container">
                                        <Image :imagePath="transfer.job.tourist.image_path"></Image>
                                    </div>
                                    <div class="details">
                                        <p>{{ transfer.job.tourist.first_name + ' ' +
                    transfer.job.tourist.last_name }}</p>
                                        <p>{{ transfer.job.tourist.contact_number }}</p>
                                    </div>
                                </div>

                                <div class="location-info">
                                    <div class="location">
                                        <h3>{{ transfer.from.main_text }}</h3>
                                        <div>Remarks: {{ transfer.remark || 'N/A' }}</div>
                                    </div>
                                    <div class="location-path">
                                        <div class="line"></div>
                                        <div class="path-icon">
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                                viewBox="0 0 24 24">
                                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                                    viewBox="0 0 24 24">
                                                    <title>steering_wheel_line</title>
                                                    <g id="steering_wheel_line" fill='none' fill-rule='evenodd'>
                                                        <path
                                                            d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                                        <path fill='#09244BFF'
                                                            d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2ZM8.313 14.781c-1.044-.835-2.46-1.158-4.108-.972a8.01 8.01 0 0 0 6.254 6.043c-.193-2.625-1.056-4.2-2.146-5.07Zm7.374 0c-1.09.872-1.953 2.446-2.146 5.07a8.01 8.01 0 0 0 6.253-6.042c-1.647-.186-3.063.137-4.107.972ZM12 10c-.95 0-1.732.37-2.306.778l-.498.347c-.524.355-1.126.72-1.801.96a6.435 6.435 0 0 1 2.167 1.134c1.124.9 1.953 2.187 2.438 3.859.485-1.672 1.314-2.96 2.438-3.859a6.434 6.434 0 0 1 2.167-1.133c-.675-.241-1.277-.606-1.801-.961l-.498-.347C13.732 10.37 12.95 10 12 10Zm0-6a8.003 8.003 0 0 0-7.862 6.513l-.043.248 2.21-.442c.582-.116 1.135-.423 1.753-.84l.477-.332C9.332 8.581 10.513 8 12 8c1.388 0 2.509.506 3.3 1.034l.642.445c.54.365 1.032.645 1.536.788l.217.052 2.21.442A8.002 8.002 0 0 0 12 4Z' />
                                                    </g>
                                                </svg>
                                            </svg>
                                        </div>
                                        <div class="line"></div>
                                    </div>
                                    <div class="location">
                                        <h3>{{ transfer.to.main_text }}</h3>
                                        <div>Tickets: {{ transfer.tickets.length }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import { httpGet } from '@/services/http'; // Assuming this is your service to make HTTP requests

export default {
    name: 'RequestsView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            id: this.$route.params.id,
            status: '',
            data: [],
            groupedData: [], // This will store the grouped and sorted data
            groupedByDate: [],
            currentFilter: 'date',
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            httpGet('/api/v1/travel_agents/jobs/assigns/' + this.id)
                .then(response => {
                    this.data = response.data.data;
                    this.processData(response.data.data);
                    this.processDataByDate(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        changeFilter(input) {
            this.currentFilter = input
        },
        processData(data) {
            // Initialize an object to accumulate grouped
            const grouped = {};

            // Traverse each detail, and for each, access the transfer information and associated tourist
            data.details.forEach(detail => {
                const transfer = detail.transfer;
                const job = transfer && transfer.job;
                const tourist = job && job.tourist;
                if (tourist && transfer) {
                    const touristUUID = tourist.uuid;
                    const transferDate = new Date(transfer.pick_up_time).toDateString(); // Normalize the date to a simple day

                    if (!grouped[touristUUID]) {
                        grouped[touristUUID] = {
                            tourist: tourist,
                            dates: {}
                        };
                    }

                    if (!grouped[touristUUID].dates[transferDate]) {
                        grouped[touristUUID].dates[transferDate] = [];
                    }

                    grouped[touristUUID].dates[transferDate].push({
                        ...transfer,
                        createdAt: detail.created_at
                    });
                }
            });

            // Convert the nested map into a sorted array by transfer dates under each tourist
            this.groupedData = Object.values(grouped).map(touristGroup => ({
                tourist: touristGroup.tourist,
                dates: Object.keys(touristGroup.dates).sort().map(date => ({
                    date,
                    transfers: touristGroup.dates[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time))
                }))
            }));

            console.log(this.groupedData);
        },
        processDataByDate(date) {
            // Initialize an object to accumulate grouped by date
            const grouped = {};

            // Traverse each detail, access the transfer information
            date.details.forEach(detail => {
                const transfer = detail.transfer;
                if (transfer) {
                    const date = new Date(transfer.pick_up_time).toDateString(); // Normalize the date to a simple day string

                    if (!grouped[date]) {
                        grouped[date] = [];
                    }

                    grouped[date].push({
                        ...transfer,
                        createdAt: detail.created_at // Storing creation date if needed
                    });
                }
            });

            // Sort transfers within each group by pick-up time
            Object.keys(grouped).forEach(date => {
                grouped[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
            });

            // Convert the sorted map into an array sorted by date
            this.groupedByDate = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b))
                .map(date => ({
                    date,
                    transfers: grouped[date]
                }));

            console.log(this.groupedByDate); // Optional: Output to console for debugging
        },
        formatDate(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },
        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: 'numeric', minute: 'numeric', hour12: false };
            return date.toLocaleTimeString('en-US', options);
        }

    }
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style scoped>
section#content .title div.button-1 {
    margin-top: 20px;
}

section#content .container {
    padding: 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
}

section#content .container h3 {
    margin-bottom: 10px;
}

section#content .container:not(:first-child) {
    margin-top: 20px;
}

.each-user:not(:last-child) {
    margin-bottom: 50px;
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}


.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 17px;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}



.location-details .user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 20px;
}

/** Tabs **/

.tabs .horizontal-nav {
    margin-bottom: 50px;
}

/** Transfer **/



.transfer-container {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.transfer-header,
.transfer-info {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.transfer-header {
    background: var(--primary-blue);
    color: #ffffff;
    border-color: var(--primary-blue);
}

.transfer-header h2 {
    margin: 0;
}

.transfer-detail {
    display: flex;
    padding: 20px;
}

.transfer-detail>div {
    margin-right: 20px;
}

.transfer-detail .time,
.transfer-detail .transfer-type,
.transfer-detail .special-slug {
    margin-bottom: 10px;
}

.line-indicator {
    width: 4px;
    background-color: var(--primary-blue);
    border-radius: 2px;
}

.location-details {
    flex-grow: 1;
}

.line-indicator.grey {
    background-color: var(--secondary-dark-grey);
}

.transfer-detail:not(:first-child) {
    border-top: 2px solid var(--secondary-dark-grey);
}

.location-info {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    gap: 20px;
}

.location-details .location {
    text-align: center;
    width: 140px;
    max-width: 200px;
}

.location-path {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.line {
    flex-grow: 1;
    height: 2px;
    background-image: linear-gradient(to right, #000 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}
</style>