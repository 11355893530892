<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Search</h1>
                <p>Dashboard > Search</p>
            </div>

            <v-row>
                <v-col cols="12" class="pa-0">
                    <v-text-field v-model="searchQuery" label="Search for keywords, guest's name, etc."
                        prepend-inner-icon="mdi-magnify" @input="filterResults" clearable></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="pa-0">
                    <v-list>
                        <v-list-item v-for="item in filteredResults" :key="item.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { httpGet } from '@/services/http';
import { toast } from 'vue3-toastify';

export default {
    name: 'SearchView',
    components: {
        TopNav,
    },
    data() {
        return {
            searchQuery: '',
            results: [],
            filteredResults: [],
        };
    },
    created() {
        // this.fetchResults();
    },
    methods: {
        fetchResults() {
            // Replace with your API endpoint
            httpGet('/api/v1/search')
                .then(response => {
                    this.results = response.data.data;
                    this.filteredResults = this.results;
                })
                .catch(error => {
                    toast.error('Error fetching results');
                });
        },
        filterResults() {
            if (this.searchQuery) {
                this.filteredResults = this.results.filter(item =>
                    item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            } else {
                this.filteredResults = this.results;
            }
        },
    },
};
</script>

<style scoped>
.v-text-field {
    margin-bottom: 20px;
}
</style>