<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Chats</h1>
                <p>Dashboard > Chat</p>
            </div>

            <div class="chat-wrapper">
                <div class="user-list has-overflow">
                    <div v-for="user in chatStore.chatSummaries" :key="user.userId" class="user"
                        @click="this.$router.push('/chats/' + user.userId)">
                        <div class="image-container">
                            <Image :imagePath="user.imagePath" />
                        </div>
                        <div class="details">
                            <h1 class="name">{{ user.userName }}
                            </h1>
                            <p class="message">
                                <v-badge color="error" content="6" inline></v-badge>
                                {{ user.latestMessage }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="chat-detail">
                    <div class="no-user">
                        <p>
                            Select a user to start chat!
                        </p>

                        <v-form ref="form" id="start-new-chat-form" class="mt-10 w-100" @submit.prevent="onUserSelect">
                            <v-col>
                                <v-autocomplete :items="chatStore.dnt" item-title="full_name" item-value="uuid"
                                    label="Select a user" v-model="id"
                                    @keyup="chatStore.searchUsers($event.target.value)"></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-btn class="button-1 w-100" type="submit" form="start-new-chat-form">
                                    Start New Chat
                                </v-btn>
                            </v-col>
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { useChatStore } from '@/stores/ChatStore';

export default {
    name: 'ChatDetailView',
    components: {
        TopNav,
        Image
    },
    setup() {
        const chatStore = useChatStore();

        return { chatStore };
    },
    data() {
        return {
            id: '',
        };
    },
    created() {
        this.chatStore.fetchChats();
    },
    methods: {
        onUserSelect() {
            if (this.id) {
                this.$router.push(`/chats/${this.id}`);
            }
        }
    }
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style scoped>
section#content form>* {
    gap: unset;
}

section#content .main-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

section#content .main-content .chat-wrapper {
    margin: 0;
    flex: 1;
    display: flex;
    overflow: hidden;
}

.user {
    font-size: 14px;
    padding: 10px 40px;
}

.user:hover {
    background-color: var(--secondary-light-grey);
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 15.5px;
}

.user .message {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-list {
    height: 100%;
    max-height: 100%;
    width: 250px;
    overflow-y: scroll;
    border-right: 1px solid var(--secondary-dark-grey);
}

.chat-detail {
    padding: 0px 20px 20px 20px;
    flex: 1;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-detail .no-user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
</style>