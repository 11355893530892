import { indexedDBService } from "@/services/indexedDBService";
import { useAuthStore } from "@/stores/AuthStore";

function masterFetch() {
    const authStore = useAuthStore();

    if (authStore.isLogin) {
        indexedDBService.getTransferTypesData();
        indexedDBService.getDtcsData();
        indexedDBService.getJobsData();
        indexedDBService.getAssignedJobRequestsData();
    }
}

export default {
    masterFetch
}