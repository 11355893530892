<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Drivers, Tour Guides & Cons</h1>
                <p>Dashboard > DTC</p>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('pending')"
                                :class="{ 'button-1': currentFilter === 'pending' }">Pending</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('approved')"
                                :class="{ 'button-1': currentFilter === 'approved' }">Active</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('cancelled')"
                                :class="{ 'button-1': currentFilter === 'cancelled' }">Cancelled</a>
                        </li>
                        <li class="nav-item add-job">
                            <router-link to="/dtc/add">+</router-link>
                        </li>
                    </ul>
                    <a class="button-2" @click.prevent="this.$router.push('/dtc/fleet/add')">
                        Add Fleet Driver
                    </a>
                </div>

                <div class="table-container">
                    <v-data-table v-if="data && data.length" :headers="headers" :items="data" :items-per-page="-1"
                        disable-pagination hide-default-footer>
                        <template v-slot:[`item.index`]="{ index }">
                            <span>{{ index + 1 }}.</span>
                        </template>
                        <template v-slot:[`item.user.first_name`]="{ item }">
                            <div class="user">
                                <div class="image-container">
                                    <Image :imagePath="item.user.image_path"></Image>
                                </div>
                                <p>{{ (item.user.first_name ?? '') + ' ' + item.user.last_name }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.user.role.role_name`]="{ item }">
                            <span>{{ item.user.role.role_name }} {{ item.user && item.user.user && item.user.user.uuid
                                    ? (item.user.user.uuid === this.profile.id ? '- Under Your Fleet' : '')
                                    : '' }}
                            </span>
                        </template>
                        <template v-slot:[`item.contact_number`]="{ item }">
                            <span>+{{ item.user.contact_numbers.find((a) => a.primary ===
                                    1).countryCode }}{{
                                    item.user.contact_numbers.find((a) => a.primary === 1).contact_number }}</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ this.$moment(item.created_at).format('DD MMM yyyy') }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <a href="" class="button-3" @click.prevent="this.$router.push('/dtc/' + item.uuid)">View</a>
                        </template>
                    </v-data-table>
                    <table class="no-data" v-else>
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'DTCView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            headers: [
                { title: 'No.', value: 'index', sortable: false },
                { title: 'Name', value: 'user.first_name', sortable: true },
                { title: 'Role', value: 'user.role.role_name', sortable: true },
                { title: 'Contact Number', value: 'contact_number', sortable: false },
                { title: 'Requested On', value: 'created_at', sortable: true },
                { title: 'Action', value: 'action', sortable: false },
            ],
            orgData: [],
            data: [],
            currentFilter: 'pending', // 'pending' or 'approved'
            subscription: null,
        };
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    created() {
        indexedDBService.getDtcsData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData = liveQuery(async () => {
                // Fetch DTC requests with their related users and details
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                // Create a map for users and request details for easy lookup
                const userMap = new Map(users.map(user => [user.uuid, user]));
                const dtcRequestDetailMap = new Map(dtcRequestDetails.map(detail => [detail.uuid, detail]));

                // Attach related users and request details to each DTC request
                const dtcRequestsWithDetails = dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        uuid: request.uuid,
                        status: request.status,
                        created_at: request.created_at,
                        updated_at: request.updated_at,
                        updated_at: request.updated_at,
                        deleted_at: request.deleted_at,
                        requester: requester ? requester : null,
                        user: user ? user : null,
                        details: details,
                    };
                });

                return dtcRequestsWithDetails;
            });

            this.subscription = observableData.subscribe((result) => {
                this.orgData = result;
                this.changeFilter(this.currentFilter);
                console.log(this.orgData)
            });
        },
        changeFilter(input) {
            this.currentFilter = input
            if (this.currentFilter == 'pending') {
                this.data = this.orgData.filter((a) => a.status == 'Pending' && a.deleted_at === null)
            } else if (this.currentFilter == 'approved') {
                this.data = this.orgData.filter((a) => a.status == 'Accepted' && a.deleted_at === null)
            } else {
                this.data = this.orgData.filter((a) => a.deleted_at !== null)
            }
        },
    },
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>