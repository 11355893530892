<template>
    <!-- Edit Contact Numbers Dialog -->
    <v-dialog v-model="editContactNumbersModal.show" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Contact Numbers</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editContactNumbersModal.show = false">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="editContactNumbersForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="8">
                                Contact Number
                            </v-col>
                            <v-col cols="12" sm="2" class="d-flex align-center">
                                Primary
                            </v-col>
                            <v-col cols="12" sm="2" class="d-flex align-center">
                                Actions
                            </v-col>
                        </v-row>
                        <v-row v-for="(contact, index) in contactNumbers" :key="index">
                            <v-col cols="12" sm="8">
                                <v-phone-input v-model="contact.number" :defaultCountry="contact.country"
                                    :rules="contactNumberValidation" @input="validateUniqueNumber(index)" />
                            </v-col>
                            <v-col cols="12" sm="2" class="d-flex align-center">
                                <v-radio-group v-model="primaryContactIndex">
                                    <v-radio :value="index" label="" disabled></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="2" class="d-flex align-center pt-0">
                                <v-btn icon @click="deleteContactNumber(index)" v-if="!contact.primary">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-btn variant="outlined" class="w-100" @click="addContactNumber" color="primary"
                                    :disabled="!(contactNumbers.length < 3)">Add Contact
                                    Number</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn class="w-100" @click="saveContactNumbers" color="primary"
                                    :loading="isLoading">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Profile</h1>
                <p>Dashboard > Profile > General > Edit</p>
            </div>

            <div class="edit-form">
                <v-form ref="form" id="edit-form" @submit.prevent="update">

                    <div class="display-image">
                        <v-col cols="12" class="pa-1 pb-3 text-center">
                            <v-avatar size="100" @click="triggerFileInput" class="avatar-container">
                                <template v-if="displayImage">
                                    <img :src="displayImage" alt="Display Image" class="avatar-image" />
                                    <p class="edit-text">Edit</p>
                                </template>
                                <template v-else>
                                    <Image :imagePath="this.profile.image_path" v-if="this.profile.image_path" />
                                    <p class="edit-text">Edit</p>
                                </template>
                            </v-avatar>
                            <v-file-input id="fileUpload" v-model="displayImageFile" accept=".jpg,.jpeg,.png"
                                label="Upload Display Image" @change="onImageChange" prepend-icon="mdi-camera"
                                style="display: none;"></v-file-input>
                        </v-col>
                    </div>

                    <div class="company-name d-flex w-100">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Company Name" v-model="companyName" :rules="companyNameValidation"
                                placeholder="Rectrix Link Pte. Ltd."></v-text-field>
                        </v-col>
                    </div>


                    <div class="contact-number">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-phone-input v-model="contactNumber" :defaultCountry="defaultContactNumberCountry"
                                :rules="contactNumberValidation" />
                        </v-col>
                    </div>

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Email Address" v-model="emailAddress" :rules="emailValidation"
                                placeholder="john.doe@example.com"></v-text-field>
                        </v-col>
                    </div>

                    <div class="password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Password" v-model="password" :rules="passwordValidation"
                                placeholder="********" type="password"></v-text-field>
                        </v-col>
                    </div>

                    <div class="confirm-password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Confirm Password" v-model="confirmPassword"
                                :rules="confirmPasswordValidation" ref="confirmPassword" placeholder="********"
                                type="password"></v-text-field>
                        </v-col>
                    </div>

                    <v-row class="pa-1 pb-5">
                        <v-col cols="12" lg="6">
                            <v-btn block type="submit" elevation="1" @click.prevent="googleLogin">
                                <img src="@/assets/icons/google.png" alt="Google logo" class="pr-5" height="20">
                                {{ this.profile.google_login ? 'Unlink' : 'Link' }} to Google
                            </v-btn>
                        </v-col>

                        <v-col cols="12" lg="6">
                            <v-btn block type="submit" elevation="1" @click.prevent="facebookLogin">
                                <img src="@/assets/icons/facebook.png" alt="Facebook logo" class="pr-5" height="20" />
                                {{ this.profile.facebook_login ? 'Unlink' : 'Link' }} to Facebook
                            </v-btn>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-btn block type="submit" elevation="1" @click.prevent="appleLogin">
                                <img src="@/assets/icons/apple.png" alt="Apple logo" class="pr-5" height="20" />
                                {{ this.profile.apple_login ? 'Unlink' : 'Link' }} to Apple
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-col cols="12" class="pa-1 pb-5">
                        <v-btn block class="button-1" type="submit" form="edit-form" :loading="isLoading">Edit
                            Profile</v-btn>
                    </v-col>

                    <div class="additional-settings">
                        <v-col cols="12" class="pa-1 pb-3 d-flex justify-end white">
                            <v-btn variant="outlined" @click="editContactNumbersModal.show = true">
                                Edit Contact Numbers
                            </v-btn>
                        </v-col>
                    </div>
                </v-form>
            </div>

        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
const FormData = require('form-data');
// Toast Notification
import { toast } from 'vue3-toastify';
import { httpGet, httpPost } from '@/services/http';

export default {
    name: 'ProfileGeneralEditView',
    components: {
        TopNav,
        Image
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    data() {
        return {
            displayImage: '',
            displayImageFile: null,
            companyName: '',
            contactNumber: '',
            emailAddress: '',
            phoneInputKey: 0, // Add a key for v-phone-input to force re-render
            defaultContactNumberCountry: "SG",
            password: '',
            confirmPassword: '',
            contactNumbers: [],
            primaryContactIndex: null, // New property
            editContactNumbersModal: {
                show: false
            },
            companyNameValidation: [
                v => !!v || 'Company name is required',
            ],
            contactNumberValidation: [
                v => !!v || 'Contact number is required',
            ],
            emailValidation: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordValidation: [
                v => (v === '' || v.length >= 8) || 'Password must be at least 8 characters'
            ],
            confirmPasswordValidation: [
                v => (v === '' || v === this.password) || 'Passwords must match'
            ],
            isLoading: false,
        }
    },
    watch: {
        "password": function (newVal) {
            if (this.confirmPassword != '') {
                this.$refs.confirmPassword.validate(); // Manually trigger the validation for startDate
            }
        }
    },
    mounted() {
        this.getProfileDetails();
        this.checkQueryParams();
    },
    methods: {
        getProfileDetails() {
            this.companyName = this.profile.last_name;
            this.contactNumber = this.profile.contact_number;
            this.emailAddress = this.profile.email_address;
            this.contactNumbers = this.profile.contact_numbers.map(contact => ({
                number: contact.contact_number,
                country: contact.country_code.country.iso_3166_1,
                primary: contact.primary
            }));
            this.primaryContactIndex = this.contactNumbers.findIndex(contact => contact.primary);
            let contactNumber = this.profile.contact_numbers.find(
                (a) => a.primary === 1
            );
            if (contactNumber) {
                this.defaultContactNumberCountry =
                    contactNumber.country_code.country.iso_3166_1;
                this.contactNumber = contactNumber.contact_number;
                this.phoneInputKey += 1;
            }
        },
        async checkQueryParams() {
            const { provider, code, error } = this.$route.query;
            this.$router.replace({ 'query': null });

            // First check if there's an error in the query params
            if (error) {
                toast.error(error);
                return; // Exit the function if there's an error
            }

            if (provider && code) {
                // Call a method to handle provider login
                if (provider == 'google' || provider == 'facebook' || provider == 'apple') {
                    const dateToSend = {
                        provider,
                        code
                    };
                    let id = toast.loading('Please wait...');
                    this.isLoading = true
                    await httpPost('/api/v1/users/auth/update', dateToSend, id)
                        .then(async response => {
                            toast.update(id, {
                                render: response.data.message,
                                type: 'success',
                                isLoading: false,
                                autoClose: 2000
                            });
                            this.getProfile()
                                .then(result => {
                                    this.getProfileDetails();
                                    this.isLoading = false;
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });
                        })
                        .catch(error => {
                            this.isLoading = false
                            console.error(error);
                        });
                }
            }
        },
        onImageChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.displayImage = URL.createObjectURL(file);
            }
        },
        triggerFileInput() {
            let fileUpload = document.getElementById('fileUpload')
            if (fileUpload != null) {
                fileUpload.click()
            }
        },
        async update() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid && isValidPhoneNumber(this.contactNumber)) {
                const parsedNumber = parsePhoneNumberFromString(this.contactNumber);

                let countryCode = parsedNumber.countryCallingCode; // Country code
                let contactNumber = parsedNumber.nationalNumber; // National number

                let data = new FormData();
                data.append('last_name', this.companyName);
                data.append('email_address', this.emailAddress);
                if (this.password != '') {
                    if (this.password !== this.confirmPassword) {
                        toast.error('Passwords does not match!')
                        return;
                    }
                    data.append('password', this.password);
                }
                if (this.displayImageFile) {
                    data.append('image_path', this.displayImageFile);
                }

                data.append('contact_number', contactNumber);
                data.append('country_code', countryCode);

                const config = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                let id = toast.loading('Please wait...');
                this.isLoading = true
                await httpPost('/api/v1/users/update', data, id, config)
                    .then(async response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        this.isLoading = false
                        this.getProfile()
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        googleLogin() {
            window.location.href = process.env.VUE_APP_API_BASE_URL + '/users/auth/google';
        },
        facebookLogin() {
            window.location.href = process.env.VUE_APP_API_BASE_URL + '/users/auth/facebook';
        },
        appleLogin() {
            window.location.href = process.env.VUE_APP_API_BASE_URL + '/users/auth/apple';
        },
        addContactNumber() {
            this.contactNumbers.push({
                number: '',
                country: 'SG', // Default country
                primary: false
            });
        },
        deleteContactNumber(index) {
            this.contactNumbers.splice(index, 1);
        },
        validateUniqueNumber(index) {
            const contact = this.contactNumbers[index];
            const duplicate = this.contactNumbers.find((c, i) => i !== index && c.number === contact.number && c.country === contact.country);

            if (duplicate) {
                this.contactNumbers[index].error = 'Duplicate contact number';
            } else {
                this.contactNumbers[index].error = null;
            }
        },
        setPrimary(index) {
            this.contactNumbers.forEach((contact, i) => {
                if (i !== index) {
                    contact.primary = false;
                }
            });
        },
        async saveContactNumbers() {
            const validNumbers = this.contactNumbers.every(contact => contact.number && !contact.error);
            const uniqueNumbers = this.contactNumbers.every((contact, index) => {
                return this.contactNumbers.findIndex(c => c.number === contact.number) === index;
            });

            if (!validNumbers || !uniqueNumbers) {
                toast.error('Please fix the errors and ensure all contact numbers are unique before saving.');
                return;
            }

            // Ensure only one primary contact is set
            this.contactNumbers.forEach((contact, index) => {
                contact.primary = (index === this.primaryContactIndex);
            });

            // Format the contact numbers
            const formattedContactNumbers = this.contactNumbers.map(contact => {
                const parsedNumber = parsePhoneNumberFromString(contact.number);
                return {
                    contact_number: parsedNumber.nationalNumber,
                    country_code: parsedNumber.countryCallingCode,
                    primary: contact.primary
                };
            });

            // Send the formatted contact numbers to the backend
            let dataToSend = {
                contact_numbers: formattedContactNumbers
            };

            // Assuming you have a method to make an API call
            let id = toast.loading('Please wait...');
            this.isLoading = true
            await httpPost('/api/v1/travel_agents/users/update/contact-numbers', dataToSend, id)
                .then(response => {
                    toast.update(id, {
                        render: response.data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 3000
                    });
                    this.getProfile()
                        .then(result => {
                            this.isLoading = false;
                            this.editContactNumbersModal.show = false;
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                })
                .catch(error => {
                    toast.remove(id);
                    this.isLoading = false;
                    console.error(error);
                });
        },
        ...mapActions(useAuthStore, ['getProfile'])
    }
}
</script>

<style src="@/assets/css/register-form.css" scoped></style>
<style scoped>
section#content .main-content {
    max-width: unset;
}

.edit-form {
    flex: 1;
}

#edit-form {
    margin: auto;
    max-width: 60%;
}

@media screen and (max-width: 950px) {
    #edit-form {
        max-width: unset;
    }
}
</style>