<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Dashboard</h1>
                <p>Welcome to Rectrix Link Agent Dashboard</p>
            </div>

            <div class="general-statistics">
                <div class="stat">
                    <p>Today's Jobs</p>
                    <h2>{{ todaysJobs.length }}</h2>
                    <p>Total</p>
                </div>
                <div class="stat">
                    <p>Rejected Jobs</p>
                    <h2>{{ actionRequiredJobs.length }}</h2>
                    <p>Total</p>
                </div>
                <div class="stat">
                    <p>All Time Jobs</p>
                    <h2>{{ activeJobs.length }}</h2>
                    <p>Total</p>
                </div>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item"><a href="#" @click.prevent="changeFilter('today')"
                                :class="{ 'button-1': currentFilter === 'today' }">Today's
                                Jobs</a></li>
                        <li class="nav-item"><a href="#" @click.prevent="changeFilter('action_required')"
                                :class="{ 'button-1': currentFilter === 'action_required' }">Rejected Jobs</a>
                        </li>
                        <li class="nav-item add-job">
                            <router-link to="/jobs/add">+</router-link>
                        </li>
                    </ul>
                </div>

                <div class="table-container">
                    <table v-if="todaysJobs.length > 0 && currentFilter === 'today'">
                        <thead>
                            <tr>
                                <th class="td-start">No.</th>
                                <th>Driver / Tour Guide</th>
                                <th>Guest Name</th>
                                <th>No. of Location(s)</th>
                                <th>Request On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(request, index) in todaysJobs">
                                <td class="td-start">{{ index + 1 }}.</td>
                                <td class="users" v-if="request.transfer_type_special_request != null">
                                    <div class="user">
                                        <div class="image-container">
                                            <Image :imagePath="request.transfer_type_special_request.user.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ request.transfer_type_special_request.user.first_name + ' ' +
                        request.transfer_type_special_request.user.last_name }}</p>
                                    </div>
                                </td>
                                <td class="user" v-else>
                                    Driver have been deleted
                                </td>
                                <td class="users">
                                    <div class="tourist user" v-for="guest in request.distinctGuests">
                                        <div class="image-container">
                                            <Image :imagePath="guest.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ guest.first_name + ' ' + guest.last_name }}</p>
                                    </div>
                                </td>
                                <td>{{ request.details.length }}</td>
                                <td>{{ formatDateTimeFull(request.created_at) }}</td>
                                <td>
                                    <a href="" class="button-3"
                                        @click.prevent="this.$router.push('/requests/' + request.uuid)">View</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="no-data" v-else-if="currentFilter === 'today'">
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                    <table v-if="actionRequiredJobs.length > 0 && currentFilter === 'action_required'">
                        <thead>
                            <tr>
                                <th class="td-start">No.</th>
                                <th>Driver / Tour Guide</th>
                                <th>Guest Name</th>
                                <th>No. of Location(s)</th>
                                <th>Request On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(request, index) in actionRequiredJobs">
                                <td class="td-start">{{ index + 1 }}.</td>
                                <td class="users" v-if="request.transfer_type_special_request != null">
                                    <div class="user">
                                        <div class="image-container">
                                            <Image :imagePath="request.transfer_type_special_request.user.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ request.transfer_type_special_request.user.first_name + ' ' +
                        request.transfer_type_special_request.user.last_name }}</p>
                                    </div>
                                </td>
                                <td class="user" v-else>
                                    Driver have been deleted
                                </td>
                                <td class="users">
                                    <div class="tourist user" v-for="guest in request.distinctGuests">
                                        <div class="image-container">
                                            <Image :imagePath="guest.image_path">
                                            </Image>
                                        </div>
                                        <p>{{ guest.first_name + ' ' + guest.last_name }}</p>
                                    </div>
                                </td>
                                <td>{{ request.details.length }}</td>
                                <td>{{ formatDateTimeFull(request.created_at) }}</td>
                                <td>
                                    <a href="" class="button-3"
                                        @click.prevent="this.$router.push('/requests/' + request.uuid)">View</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="no-data" v-else-if="currentFilter === 'action_required'">
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import { httpGet } from '@/services/http';

export default
    {
        name: 'DashboardView',
        components: {
            TopNav,
            Image
        },
        data() {
            return {
                currentFilter: 'today',
                todaysJobs: [],
                actionRequiredJobs: [],
                activeJobs: [],
            }
        },
        created() {
            localStorage.forcedAllowLogin = true;
            this.getActionRequiredJobs();
            this.getAllActiveJobs();
        },
        mounted() {

        },
        methods: {
            getActionRequiredJobs() {

            },
            consolidateGuestNames(details) {
                const guests = new Set();
                details.forEach(detail => {
                    // Assuming each detail has a guest information
                    guests.add(JSON.stringify(detail.transfer.job.tourist));
                });

                return [...guests].map((item) => JSON.parse(item))
            },
            getAllActiveJobs() {
                httpGet('/api/v1/travel_agents/jobs/assigns/actives')
                    .then(response => {
                        if (response.data.data) {
                            this.activeJobs = response.data.data;
                            this.todaysJobs = this.getActiveJobsToday(response.data.data)
                            console.log(this.activeJobs)
                            console.log(this.todaysJobs)
                            // this.todaysJobs = response.data.data;
                            this.todaysJobs.forEach((request) => {
                                request.distinctGuests = this.consolidateGuestNames(request.details);
                            })
                        }
                    })
                    .catch(error => {
                        console.error('Failed to load data:', error);
                    });
            },
            getActiveJobsToday(data) {
                const todayJobs = new Set();  // Using a Set to avoid duplicates

                data.forEach(item => {
                    item.details.forEach(detail => {
                        // Assuming pick_up_time is an ISO string, or adjust accordingly
                        const jobDate = new Date(detail.transfer.pick_up_time);
                        console.log(`Job Date: ${jobDate.toISOString()}`); // Debugging line

                        if (this.isToday(jobDate)) {
                            todayJobs.add(item);
                        }
                    });
                });

                return Array.from(todayJobs);
            },

            isToday(date) {
                const today = new Date();
                const isToday = date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();
                console.log(`Comparing: ${date.toISOString()} to ${today.toISOString()} - Result: ${isToday}`); // Debugging line
                return isToday;
            },

            // getActiveJobsToday(data) {
            //     const todayJobs = new Set();  // Using a Set to avoid duplicates

            //     data.forEach(item => {
            //         item.details.forEach(detail => {
            //             const jobDate = new Date(detail.transfer.pick_up_time);

            //             if (this.isToday(jobDate)) {
            //                 todayJobs.add(item);
            //             }
            //         });
            //     });

            //     return Array.from(todayJobs);
            // },
            // isToday(date) {
            //     const today = new Date();
            //     return date.getDate() === today.getDate() &&
            //         date.getMonth() === today.getMonth() &&
            //         date.getFullYear() === today.getFullYear();
            // },
            changeFilter(input) {
                this.currentFilter = input
                // if (this.currentFilter == 'pending') {
                //     this.data = this.orgData.filter((a) => a.status == 'Pending')
                // } else if (this.currentFilter == 'approved') {
                //     this.data = this.orgData.filter((a) => a.status == 'Approved')
                // } else {
                //     this.data = []
                // }
                // console.log(JSON.stringify(this.data))
            },
            formatDateTimeFull(date) {
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const d = new Date(date);
                const day = d.getDate();
                const month = months[d.getMonth()];
                const year = d.getFullYear();

                // Extract hours and minutes for time formatting in 24-hour format
                const hours = d.getHours();
                const minutes = d.getMinutes();

                // Ensuring two-digit hours and minutes
                const hoursFormatted = hours < 10 ? '0' + hours : hours;
                const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;

                // Format the date and time string
                return `${day} ${month} ${year} ${hoursFormatted}:${minutesFormatted}`;
            },
        }
    }
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>