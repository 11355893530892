<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Concurrent Login</h1>
                <p>Manage your login sessions</p>
            </div>

            <div class="sessions-management">
                <div class="card mb-4">
                    <div class="card-content">
                        <v-alert type="info" dense outlined color="primary" class="mb-4"
                            v-if="this.profile && this.profile.allowed_concurrent_logins && this.profile.active_tokens_count">
                            You are allowed {{ this.profile.allowed_concurrent_logins }} concurrent logins.
                            You currently have {{ this.profile.active_tokens_count }} active
                            {{ this.profile.active_tokens_count === 1 ? 'session' : 'sessions' }}.
                        </v-alert>

                        <div class="session-list" v-if="sessions && sessions.length">
                            <h2>Active Sessions</h2>
                            <ul>
                                <li v-for="session in sessions" :key="session.id" class="session-item">
                                    <div class="session-icon">
                                        <v-icon :color="session.isCurrent ? 'success' : 'grey'">
                                            {{ getDeviceIcon(session) }}
                                        </v-icon>
                                    </div>
                                    <div class="session-details">
                                        <h3>{{ session.device }} ({{ session.deviceType ?? ('N/A') }})</h3>
                                        <p>
                                            Last Active: {{ session.lastActive }}
                                            <span v-if="session.isCurrent" class="current-badge">Current</span>
                                        </p>
                                        <p>
                                            IP Address: {{ session.ipAddress ?? 'N/A' }}
                                        </p>
                                        <p>
                                            Time Zone: {{ session.timeZone ?? 'N/A' }}
                                        </p>
                                    </div>
                                    <div class="session-action">
                                        <v-btn @click="confirmAction('logout', session.id)" v-if="!session.isCurrent"
                                            color="primary" text :loading="loadingStates.logout"
                                            :disabled="isAnyActionInProgress && !loadingStates.logout">
                                            Logout
                                        </v-btn>
                                        <v-btn @click="confirmAction('logoutCurrent')" v-else color="primary" text
                                            :loading="loadingStates.logoutCurrent"
                                            :disabled="isAnyActionInProgress && !loadingStates.logoutCurrent">
                                            Logout This Device
                                        </v-btn>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-else-if="sessions === null">
                            <p>Loading sessions...</p>
                        </div>
                        <div v-else>
                            <p>No active sessions found.</p>
                        </div>
                    </div>

                    <hr class="divider">

                    <div class="card-actions">
                        <v-btn @click="confirmAction('logoutAll')" color="error"
                            :disabled="!sessions || sessions.length <= 1 || (isAnyActionInProgress && !loadingStates.logoutAll)"
                            prepend-icon="mdi-logout-variant" :loading="loadingStates.logoutAll">
                            Logout All Except Current Session
                        </v-btn>
                    </div>
                </div>

                <div v-if="profile && profile.active_tokens_count >= profile.allowed_concurrent_logins" class="upgrade">
                    <v-icon color="warning" size="36" class="upgrade-icon">mdi-alert-circle</v-icon>
                    <h2 class="upgrade-title">Session Limit Reached</h2>
                    <div class="upgrade-content">
                        <p>You've reached your maximum number of concurrent logins. Upgrade your plan to get more!</p>
                        <p class="mt-2">Need More Sessions?</p>
                        <v-btn @click="confirmAction('addConcurrentLogin')" color="primary" prepend-icon="mdi-cart"
                            class="mt-3" :loading="loadingStates.addConcurrentLogin"
                            :disabled="isAnyActionInProgress && !loadingStates.addConcurrentLogin">
                            Purchase More
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-text>
                    <v-card-title class="d-flex justify-space-between align-center">
                        <span class="headline">{{ dialogTitle }}</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog" :disabled="isLoading">
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-form ref="form">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <p>{{ dialogMessage }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn class="button-2 w-100" @click="closeDialog" :disabled="isLoading"
                                        color="error">
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn class="button-1 w-100" @click="performAction" :loading="isLoading"
                                        color="primary">
                                        Confirm
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { toast } from 'vue3-toastify'
import { httpGet, httpPost } from '@/services/http';
import { mapState, mapActions } from 'pinia';

export default {
    name: 'SessionsManagementView',
    components: {
        TopNav,
    },
    data() {
        return {
            sessions: null,
            dialog: false,
            dialogTitle: '',
            dialogMessage: '',
            currentAction: null,
            currentSessionId: null,
            loadingStates: {
                logout: false,
                logoutCurrent: false,
                logoutAll: false,
                addConcurrentLogin: false
            }
        }
    },
    computed: {
        isAnyActionInProgress() {
            return Object.values(this.loadingStates).some(state => state);
        },
        ...mapState(useAuthStore, ['profile']),
    },
    mounted() {
        this.fetchSessions()
    },
    methods: {
        fetchSessions() {
            if ((this.profile.current_token_order <= this.profile.allowed_concurrent_logins) || localStorage.forcedAllowLogin) {
                this.$router.push('/');
                return;
            }

            httpGet('/api/v1/travel_agents/devices/sessions')
                .then(response => {
                    if (response.data.status) {
                        this.sessions = response.data.data;
                    } else {
                        throw new Error(response.data.message);
                    }
                })
                .catch(error => {
                    toast.error('Failed to fetch sessions: ' + (error.message || 'Unknown error'));
                    console.error(error);
                });
        },
        confirmAction(action, sessionId = null) {
            this.currentAction = action;
            this.currentSessionId = sessionId;
            switch (action) {
                case 'logout':
                    this.dialogTitle = 'Confirm Logout';
                    this.dialogMessage = 'Are you sure you want to log out this session?';
                    break;
                case 'logoutCurrent':
                    this.dialogTitle = 'Confirm Logout';
                    this.dialogMessage = 'Are you sure you want to log out your current session?';
                    break;
                case 'logoutAll':
                    this.dialogTitle = 'Confirm Logout All';
                    this.dialogMessage = 'Are you sure you want to log out all sessions except the current one?';
                    break;
                case 'addConcurrentLogin':
                    this.dialogTitle = 'Confirm Purchase';
                    this.dialogMessage = 'Are you sure you want to purchase an additional concurrent login?';
                    break;
            }
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.currentAction = null;
            this.currentSessionId = null;
        },
        performAction() {
            this.loadingStates[this.currentAction] = true;
            switch (this.currentAction) {
                case 'logout':
                    this.logoutSession(this.currentSessionId);
                    break;
                case 'logoutCurrent':
                    this.logout();
                    break;
                case 'logoutAll':
                    this.logoutAllExceptCurrent();
                    break;
                case 'addConcurrentLogin':
                    this.addConcurrentLogin();
                    break;
            }
            this.closeDialog();
        },
        logoutSession(sessionId) {
            let data = { "id": sessionId };
            let id = toast.loading('Please wait...');

            httpPost(`/api/v1/travel_agents/devices/sessions/logout`, data, id)
                .then(async response => {
                    if (response.data.status) {
                        this.sessions = this.sessions.filter(session => session.id !== sessionId);
                        toast.update(id, {
                            render: "Session logged out successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        await this.getProfile();
                    } else {
                        throw new Error(response.data.message);
                    }
                })
                .catch(error => {
                    toast.remove(id);
                    console.error(error);
                })
                .finally(() => {
                    this.loadingStates.logout = false;
                });
        },
        logoutAllExceptCurrent() {
            let id = toast.loading('Please wait...');
            httpPost('/api/v1/travel_agents/devices/sessions/logout-all-except-current', {}, id)
                .then(async response => {
                    if (response.data.status) {
                        this.sessions = this.sessions.filter(session => session.isCurrent);
                        toast.update(id, {
                            render: "All other sessions logged out successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        await this.getProfile();
                    } else {
                        throw new Error(response.data.message);
                    }
                })
                .catch(error => {
                    toast.remove(id);
                    console.error(error);
                })
                .finally(() => {
                    this.loadingStates.logoutAll = false;
                });
        },
        addConcurrentLogin() {
            let id = toast.loading('Please wait...');
            httpPost('/api/v1/travel_agents/subscriptions/add-concurrent-login', {}, id)
                .then(async response => {
                    if (response.data.status) {
                        toast.update(id, {
                            render: "Concurrent login added successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        await this.getProfile();
                        this.fetchSessions();
                    } else {
                        throw new Error(response.data.message);
                    }
                })
                .catch(error => {
                    toast.remove(id);
                    console.error(error);
                })
                .finally(() => {
                    this.loadingStates.addConcurrentLogin = false;
                });
        },
        getDeviceIcon(session) {
            switch (session.deviceType) {
                case 'iPhone':
                    return 'mdi-cellphone'
                case 'iPad':
                    return 'mdi-tablet'
                case 'macOS':
                    return 'mdi-laptop'
                case 'Windows':
                default:
                    return 'mdi-desktop-tower-monitor'
            }
        },
        ...mapActions(useAuthStore, ['getProfile', 'logout'])
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.title p {
    color: #666;
}

.sessions-management {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.card {
    margin-bottom: 1rem;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    background-color: var(--secondary-light-grey);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-content {
    padding: 1rem;
}

.session-list h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.session-item {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
}

.session-item:last-child {
    border-bottom: none;
}

.session-icon {
    margin-right: 2rem;
}

.session-details {
    flex: 1;
}

.session-details h3 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
}

.session-details p {
    color: #666;
}

.current-badge {
    background-color: var(--primary-blue);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 0.5rem;
}

.session-action {
    margin-left: auto;
}

.divider {
    border: none;
    border-top: 1px solid #eee;
}

.card-actions {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.upgrade {
    padding: 24px;
    text-align: center;
    margin-top: 24px;
}

.upgrade-icon {
    margin-bottom: 16px;
}

.upgrade-title {
    font-size: 22px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 8px;
}

.upgrade-content {
    color: #5c6b7a;
    font-size: 14px;
    line-height: 1.5;
}

.upgrade-btn {
    margin-top: 16px !important;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0.5px;
    padding: 0 24px !important;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .main-content {
        max-width: 95%;
    }
}

@media (max-width: 960px) {
    .title h1 {
        font-size: 1.8rem;
    }

    .title p {
        font-size: 1rem;
    }

    .card {
        padding: 15px;
    }

    .session-icon {
        margin-right: 1.5rem;
    }
}

@media (max-width: 768px) {
    .session-item {
        flex-wrap: wrap;
    }

    .session-icon {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }

    .session-details {
        width: calc(100% - 60px);
    }

    .session-action {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }

    .card-actions {
        flex-direction: column;
        align-items: stretch;
    }

    .card-actions .v-btn {
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 600px) {
    .main-content {
        padding: 10px;
    }

    .title h1 {
        font-size: 1.5rem;
    }

    .title p {
        font-size: 0.9rem;
    }

    .session-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .session-icon {
        margin-bottom: 0.5rem;
    }

    .session-details {
        width: 100%;
    }

    .session-action {
        margin-left: 0;
        margin-top: 0.5rem;
        align-self: stretch;
    }

    .session-action .v-btn {
        margin: 10px auto;
        margin-left: 0;
    }

    .upgrade {
        padding: 20px;
    }

    .upgrade-title {
        font-size: 20px;
    }

    .upgrade-content {
        font-size: 14px;
    }

    .upgrade-btn {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .title h1 {
        font-size: 1.3rem;
    }

    .title p {
        font-size: 0.8rem;
    }

    .card-content {
        padding: 0.5rem;
    }
}
</style>