<template>
    <div class="status-chip-wrapper">
        <div size="small" :color="color" class="status-chip" v-bind="$attrs">
            <span class="status-dot" :class="[color]"></span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusChip',
    props: {
        color: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>
.status-chip-wrapper {
    display: inline-block;
}


.status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.status-dot::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.status-dot.success {
    background-color: rgb(var(--v-theme-success));
}

.status-dot.success::before {
    background-color: rgb(var(--v-theme-success));
}

.status-dot.error {
    background-color: rgb(var(--v-theme-error));
}

.status-dot.error::before {
    background-color: rgb(var(--v-theme-error));
}

.status-dot.warning {
    background-color: rgb(var(--v-theme-warning));
}

.status-dot.warning::before {
    background-color: rgb(var(--v-theme-warning));
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(3);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Optional: Adjust the chip appearance */
:deep(.v-chip) {
    font-weight: 500;
}

:deep(.v-chip.v-chip--size-small) {
    height: 26px;
}
</style>