<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Notifications</h1>
                <p>Dashboard > Notifications</p>
            </div>

            <v-row>
                <v-col cols="12" class="pa-0">
                    <v-list>
                        <v-list-item v-for="notification in notifications" :key="notification.id"
                            class="notification-item pa-5">
                            <v-list-item-content>
                                <div class="notification-header">
                                    <v-icon small>mdi-bell-ring</v-icon>
                                    <v-list-item-title>{{ notification.title }}</v-list-item-title>
                                    <v-spacer></v-spacer>
                                    <span class="notification-time">{{ formatDate(notification.timestamp) }}</span>
                                </div>
                                <v-list-item-subtitle>{{ notification.message }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { httpGet } from '@/services/http';
import { toast } from 'vue3-toastify';

export default {
    name: 'NotificationView',
    components: {
        TopNav,
    },
    data() {
        return {
            searchQuery: '',
            notifications: [
                {
                    id: 1,
                    title: 'Sample Notification',
                    message: 'This is a sample notification message.',
                    timestamp: '2024-05-16T10:00:00Z',
                },
                {
                    id: 1,
                    title: 'Sample Notification',
                    message: 'This is a sample notification message.',
                    timestamp: '2024-05-16T10:00:00Z',
                },
                {
                    id: 1,
                    title: 'Sample Notification',
                    message: 'This is a sample notification message.',
                    timestamp: '2024-05-16T10:00:00Z',
                },
            ],
        };
    },
    created() {
        // Uncomment this line to fetch notifications from the API
        // this.fetchNotifications();
    },
    methods: {
        fetchNotifications() {
            httpGet('/api/v1/notifications')
                .then(response => {
                    this.notifications = response.data.data;
                })
                .catch(error => {
                    toast.error('Error fetching notifications');
                });
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            return new Date(dateString).toLocaleDateString('en-US', options);
        },
    },
};
</script>

<style scoped>
.notification-item {
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius) !important;
    margin-bottom: 20px;
    overflow: hidden;
}

.notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Added bottom margin for spacing between title and message */
}

.notification-header .v-list-item-title {
    margin-left: 15px;
    /* Slightly increased margin-left for more space between icon and title */
    font-weight: 500;
    /* Slightly bolder font for the title */
}

.notification-time {
    font-size: 0.875rem;
    color: gray;
    margin-left: auto;
    /* Moved time to the right */
}
</style>
