<template>
    <section class="loading" v-if="this.isLoading">
        <img src="@/assets/logo/Main_2.png" alt="Logo Light">
        <p>Retrieving your data...</p>
    </section>
</template>

<script>
import { mapStores, mapState } from 'pinia'
import { useLoadingStore } from '@/stores/LoadingStore'

export default {
    name: 'Loading',
    computed: {
        ...mapStores(useLoadingStore),
        ...mapState(useLoadingStore, ['isLoading']),
    },
}
</script>


<style scoped>
section.loading {
    position: fixed;
    inset: 0 0;
    overflow: hidden;
    z-index: 1500;
    background-color: var(--secondary-white);
    display: grid;
    place-content: center;
}

section.loading p {
    position: absolute;
    inset: 55% 50%;
    width: 100%;
    text-align: center;
    translate: -50% -50%;
    color: var(--primary-black);
}

section.loading::before {
    position: absolute;
    inset: 48% 50%;
    translate: -50% -50%;
    content: '';
    display: grid;
    place-content: center;
    border: 3px solid var(--secondary-light-grey);
    border-bottom-color: var(--primary-blue);
    border-radius: 50%;
    height: 70px;
    width: 70px;
    animation: 1s rotate linear infinite;
}

section.loading img {
    position: absolute;
    inset: 48% 50%;
    translate: -50% -50%;
    height: 30px;
    width: 30px;
    object-fit: contain;
}

@keyframes rotate {
    0% {
        rotate: 0deg;
    }

    50% {
        rotate: 180deg;
    }

    100% {
        rotate: 360deg;
    }
}
</style>