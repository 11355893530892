import { defineStore } from "pinia";

export const useSideBarStore = defineStore("SideBarStore", {
    // State
    state: () => {
        return {
            showSideBar: localStorage.showSideBar === 'true'
        }
    },
    // Actions
    actions: {
        toggleSideBar() {
            localStorage.showSideBar = !this.showSideBar
            this.showSideBar = !this.showSideBar
        }
    }
})

