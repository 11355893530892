<template>
    <section id="content">
        <Loading v-if="isLoading" />
        <div v-else class="main-content">
            <TopNav />
            <div class="title">
                <h1>Subscription History</h1>
                <p>Dashboard > Subscription</p>
            </div>


            <div class="subscription-container container">
                <div class="table-container important-notes pa-4 mb-5">
                    <ul>
                        <h3>Important Notes</h3>
                        <li>The billing table for the active subscription shown here will apply to the next billing
                            cycle.</li>
                        <li>If you are within your trial period, you will only be charged when the trial ends.</li>
                        <li>Newly added add-ons will be charged in the next billing cycle.</li>
                        <li>To remove or reduce add-ons, please contact us at support@rectrixlink.com.</li>
                    </ul>
                    <v-btn @click="updateCard" :loading="isCardUpdateLoading" :disabled="isCardUpdateLoading"
                        variant="outlined" color="primary" class="mt-5">
                        Update Card Details
                    </v-btn>
                </div>

                <div v-if="subscriptions" v-for="(subscription, index) in  subscriptions " :key="index"
                    class="subscription-row">
                    <div class="subscription-header" :class="{ 'current': subscription.isCurrent }">
                        <p>Renewing Date</p>
                        <h2>{{ subscription.auto_billing_on }}</h2>
                        <span v-if="subscription.isCurrent" class="current-tag">Active</span>
                        <div class="subscription-actions">
                            <v-btn v-if="subscription.isCurrent"
                                :color="subscription.ends_at === null ? 'error' : 'success'"
                                @click="openConfirmDialog(subscription.ends_at === null ? 'stop' : 'resume')"
                                :loading="actionLoading" class="mt-2">
                                {{ subscription.ends_at === null ? 'Stop Subscription' : 'Resume Subscription' }}
                            </v-btn>
                        </div>
                    </div>
                    <div class="subscription-body table-container">
                        <v-data-table v-if="subscriptions && subscriptions.length" :headers="headers"
                            :items="subscription.items" :items-per-page="-1" disable-pagination hide-default-footer>
                            <template v-slot:item.price="{ item }">
                                ${{ item.price.toFixed(2) }}
                            </template>
                            <template v-slot:body.append>
                                <tr class="total-row">
                                    <td colspan="3" class="text-right"><strong>Total</strong></td>
                                    <td colspan="1"><strong>${{ subscription.total.toFixed(2) }}</strong></td>
                                </tr>
                                <tr>
                                    <td colspan="1">Receipts:</td>
                                    <td colspan="3">
                                        <div v-if="subscription.receipts" class="d-flex flex-wrap align-center">
                                            <template v-for="(receipt, index) in subscription.receipts.slice(0, 5)"
                                                :key="receipt.id">
                                                <v-chip class="ma-1" color="primary" :href="receipt.receipt_url"
                                                    target="_blank" link>
                                                    {{ receipt.date }} (${{ receipt.amount.toFixed(2) }})
                                                </v-chip>
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <table class="no-data" v-else>
                            <thead>
                                <tr>
                                    <td class="td-start">There are currently no subscription data</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <table class="no-data" v-else>
                    <thead>
                        <tr>
                            <td class="td-start">Loading...</td>
                        </tr>
                    </thead>
                </table>
            </div>

            <!-- Confirmation Dialog -->
            <v-dialog v-model="confirmDialog" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-card-title class="d-flex justify-space-between align-center">
                            <span class="headline">Confirm Action</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeConfirmDialog">
                                <v-icon color="black">mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-form ref="form">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <p>{{ confirmMessage }}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-btn class="button-2 w-100" @click="closeConfirmDialog" color="error">
                                            Cancel
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn class="button-1 w-100" @click="executeConfirmedAction" color="primary">
                                            Confirm
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav';
import Loading from '@/components/Loading';
import { httpGet, httpPost } from '@/services/http';
import { mapStores, mapState, mapActions } from 'pinia';
import { useLoadingStore } from '@/stores/LoadingStore';
import { toast } from "vue3-toastify";

export default {
    name: 'SubscriptionView',
    components: {
        TopNav,
        Loading
    },
    data() {
        return {
            subscriptions: [],
            headers: [
                { title: 'No.', value: 'index', sortable: true },
                { title: 'Plan Type', value: 'planType', sortable: true },
                { title: 'Quantity', value: 'quantity', sortable: true, align: 'center' },
                { title: 'Price', value: 'price', sortable: true, align: 'right' },
            ],
            confirmDialog: false,
            actionType: null,
            confirmMessage: '',
            actionLoading: false,
            isCardUpdateLoading: false,
        }
    },
    computed: {
        ...mapStores(useLoadingStore),
        ...mapState(useLoadingStore, ['isLoading']),
    },
    created() {
        this.getAllSubscriptionHistory();
    },
    methods: {
        getAllSubscriptionHistory() {
            this.setLoading(true);
            httpGet('/api/v1/travel_agents/subscriptions')
                .then(response => {
                    this.subscriptions = response.data.data.map(subscription => ({
                        ...subscription,
                        items: subscription.items.map((item, index) => ({
                            ...item,
                            index: index + 1 + '.'
                        }))
                    }));
                    console.log(this.subscriptions);
                })
                .catch(error => {
                    console.error('Failed to load data:', error);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
        openConfirmDialog(action) {
            this.actionType = action;
            this.confirmMessage = action === 'stop'
                ? 'Are you sure you want to stop your subscription? This will take effect at the end of your current billing cycle.'
                : 'Are you sure you want to resume your subscription? This will reactivate your subscription immediately.';
            this.confirmDialog = true;
        },
        closeConfirmDialog() {
            this.confirmDialog = false;
            this.actionType = null;
            this.confirmMessage = '';
        },
        async executeConfirmedAction() {
            this.confirmDialog = false;
            this.actionLoading = true;
            try {
                if (this.actionType === 'stop') {
                    await this.stopSubscription();
                } else if (this.actionType === 'resume') {
                    await this.resumeSubscription();
                }
                this.getAllSubscriptionHistory(); // Refresh the data
            } catch (error) {
                console.error(`Failed to ${this.actionType} subscription:`, error);
            } finally {
                this.actionLoading = false;
                this.actionType = null;
            }
        },
        async updateCard(formData) {
            let id = toast.loading("Redirecting...");
            this.isCardUpdateLoading = true;
            try {
                // Make an API call to get the update URL or session ID
                const response = await httpPost('/api/v1/travel_agents/subscriptions/update-card', formData, id);

                if (response.data && response.data.url) {
                    toast.update(id, {
                        render: "Redirecting to change card detail...",
                        type: "info",
                        isLoading: false,
                        autoClose: 2000,
                    });
                    window.location.href = response.data.url;
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                console.error('Failed to initiate card update:', error);
                // Show an error message to the user
                // You might want to use a toast notification or alert component here
                this.$emit('show-error', 'Failed to initiate card update. Please try again.');
            } finally {
                this.isCardUpdateLoading = false;
            }
        },
        async stopSubscription() {
            await httpPost(`/api/v1/travel_agents/subscriptions/cancel`);
        },
        async resumeSubscription() {
            await httpPost(`/api/v1/travel_agents/subscriptions/resume`);
        },
        ...mapActions(useLoadingStore, ['setLoading']),
    },
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
section#content .container {
    padding: unset;
    background-color: unset;
    border: unset;
}

.subscription-actions {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

section#content .container h3 {
    margin-bottom: 10px;
}

.important-notes ul {
    list-style-type: none;
}

.important-notes ul li::before {
    content: ">";
    color: var(--primary-blue);
    font-weight: bold;
    margin-right: 0.5rem;
}

.table-container {
    margin: unset;
}

.subscription-container {
    margin-top: 2rem;
}

.subscription-row {
    display: flex;
    background-color: var(--secondary-white);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.subscription-row:hover {
    transform: translateY(-5px);
}

.subscription-header {
    text-align: center;
    background-color: var(--primary-blue);
    color: var(--secondary-white);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.current-tag {
    background-color: var(--secondary-white);
    color: var(--primary-blue);
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.subscription-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.subscription-details {
    flex-grow: 1;
}

.plan-labels,
.plan-item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
}

.plan-labels {
    font-weight: bold;
    color: var(--text-color);
}

.quantity {
    text-align: center;
}

hr {
    border-top: 1px solid var(--border-color);
    margin: 5px 0;
}

.total {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding-top: 1rem;
    text-align: right;
}

.total-label {
    font-weight: bold;
    margin-right: 0.5rem;
    text-align: right;
}

.subscription-actions {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

@media screen and (max-width: 768px) {
    .subscription-actions {
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .subscription-row {
        flex-direction: column;
    }

    .subscription-header {
        width: 100%;
        padding: 1rem;
        max-width: unset;
        min-width: unset;
    }

    .plan-labels,
    .plan-item {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        font-size: 0.9rem;
    }
}
</style>