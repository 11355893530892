<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Itinerary Templates</h1>
                <p>Dashboard > Profile > Itinerary Templates</p>
            </div>

            <div class="templates-wrapper">
                <v-form ref="templateForm" @submit.prevent="updateTemplate">
                    <div class="template-item" v-for="(template, index) in  data " :key="index"
                        :class="{ 'active': template.uuid === this.selectedTemplate }">
                        <label :for="`template-${index}`" class="template-label">
                            <input type="radio" :id="`template-${index}`" :value="template.uuid"
                                v-model="selectedTemplate">
                            <h3>{{ template.template_name }}</h3>
                            <p v-if="profile && profile.travel_agent && profile.travel_agent.itinerary">
                                {{ profile.travel_agent.itinerary.uuid === template.uuid ? 'Currently In Use' : '' }}
                            </p>
                        </label>
                    </div>
                    <div class="itinerary-header-footer">
                        <div class="guide">
                            <h3>Customisation</h3>
                            <p>The header and footer will be displayed at the respective portion.</p>
                        </div>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-textarea label="Itinerary Header" :rules="itineraryValidation.header"
                                    v-model="itineraryHeader"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea label="Itinerary Footer" :rules="itineraryValidation.footer"
                                    v-model="itineraryFooter"></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="buttons d-flex justify-end">
                        <v-btn type="submit" class="button-1" :loading="isLoading">Update Template</v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </section>
</template>
<script>
import TopNav from '@/components/TopNav.vue';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import { httpGet, httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';

export default {
    name: 'ItineraryTemplatesView',
    components: {
        TopNav
    },
    data() {
        return {
            data: [],
            selectedTemplate: null,
            itineraryHeader: '',
            itineraryFooter: '',
            isLoading: false,
            itineraryValidation: {
                header: [(v) => !!v || "Itinerary header is required"],
                footer: [(v) => !!v || "Itinerary footer is required"],
            },
        };
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    created() {
        this.itineraryHeader = this.profile.travel_agent.itinerary_header
        this.itineraryFooter = this.profile.travel_agent.itinerary_footer
        this.fetchData();
    },
    methods: {
        fetchData() {
            httpGet('/api/v1/travel_agents/itineraries')
                .then(response => {
                    this.data = response.data.data;
                    // Set the selected template to the current one in use if available
                    if (this.profile && this.profile.travel_agent && this.profile.travel_agent.itinerary) {
                        this.selectedTemplate = this.profile.travel_agent.itinerary.uuid;
                    }
                })
                .catch(error => {
                    console.error('Failed to load data:', error);
                });
        },
        async updateTemplate() {
            const isValid = await this.$refs.templateForm.validate();

            if (isValid.valid) {
                let dataToSend = {
                    id: this.selectedTemplate,
                    itinerary_header: this.itineraryHeader,
                    itinerary_footer: this.itineraryFooter,
                };

                let id = toast.loading('Please wait...');
                this.isLoading = true;
                httpPost(`/api/v1/travel_agents/itineraries/update`, dataToSend, id)
                    .then(response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        this.getProfile()
                            .then(result => {
                                this.isLoading = false;
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    })
                    .catch(error => {
                        console.error('Failed to update template:', error);
                        this.isLoading = false;
                    });
            }
        },
        ...mapActions(useAuthStore, ['getProfile']),
    }
};
</script>
<style scoped>
.templates-wrapper {
    margin-top: 20px;
}

.template-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.template-item input[type="radio"] {
    margin-right: 15px;
}

.template-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.template-item:hover,
.template-item.active {
    background-color: #f5f5f5;
}

.template-item h3 {
    margin: 0;
    flex-grow: 1;
}

.itinerary-header-footer .guide {
    padding: 20px 0px;
}
</style>
