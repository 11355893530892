<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Chats</h1>
                <p>Dashboard > Chat</p>
            </div>

            <div class="chat-wrapper">
                <div class="user-list has-overflow">
                    <div v-for="user in chatStore.chatSummaries" :key="user.userId" class="user"
                        @click="this.$router.push('/chats/' + user.userId)"
                        :class="{ 'active': user.userId === chatStore.user.uuid }">
                        <div class="image-container">
                            <Image :imagePath="user.imagePath" />
                        </div>
                        <div class="details">
                            <h1 class="name">{{ user.userName }}</h1>
                            <p class="message">
                                <v-badge color="error" content="6" inline></v-badge>
                                {{ user.latestMessage }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="chat-detail">
                    <div class="user" v-if="chatStore.user && chatStore.user.role">
                        <div class="image-container">
                            <Image :imagePath="chatStore.user.image_path" />
                        </div>
                        <div class="details">
                            <h1 class="name">{{ chatStore.user.first_name }} {{ chatStore.user.last_name }}</h1>
                            <p>{{ chatStore.user.role.role_name }}</p>
                        </div>
                    </div>
                    <p class="no-chat" v-if="chatStore.messages.length == 0">Send a message to start conversation!</p>
                    <div class="conversation has-overflow" ref="conversation">
                        <!-- Iterate over messages when available -->
                        <div v-for="message in chatStore.messages" :key="message.id"
                            :class="message.to.uuid === id ? 'my-message' : 'opp-message'">
                            <div class="chat">
                                <div class="details">
                                    <p>{{ message.content }}</p>
                                    <p class="time">{{ formatDate(message.created_at) }}</p>
                                </div>
                            </div>
                        </div>
                        <v-btn v-if="showScrollButton" @click="scrollToBottom" class="scroll-button button-1"
                            density="compact" icon="mdi-arrow-down">
                        </v-btn>
                    </div>
                    <v-form ref="form" id="message-controls" @submit.prevent="send">
                        <v-row>
                            <v-col cols="9">
                                <v-text-field v-model="chatStore.messageText" label="Type a message"
                                    placeholder="Start chatting now..." filled solo autocomplete="off">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-btn class="button-1 w-100" type="submit">
                                    Send
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { useChatStore } from '@/stores/ChatStore';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, watch, ref, nextTick } from 'vue';

export default {
    name: 'ChatDetailView',
    components: {
        TopNav,
        Image
    },
    setup() {
        const chatStore = useChatStore();
        const route = useRoute();
        const router = useRouter();
        const id = ref(route.params.id);
        const conversation = ref(null);
        const showScrollButton = ref(false);

        // Function to scroll to the bottom of the conversation
        const scrollToBottom = () => {
            if (conversation.value) {
                nextTick(() => {
                    conversation.value.scrollTo({
                        top: conversation.value.scrollHeight,
                        behavior: 'smooth'
                    });
                    showScrollButton.value = false; // Hide the button when at the bottom
                });
            }
        };

        // Function to handle scroll event
        const handleScroll = () => {
            if (conversation.value) {
                const isAtBottom = conversation.value.scrollHeight - conversation.value.scrollTop <= conversation.value.clientHeight + 20;
                showScrollButton.value = !isAtBottom;
            }
        };

        // Fetch chats and user details when the component is mounted
        onMounted(() => {
            const currentUserId = JSON.parse(localStorage.getItem('profile')).id;
            if (id.value == currentUserId) {
                router.push('/chats')
            }
            chatStore.fetchChats();
            if (id.value) {
                chatStore.fetchUser(id.value);
            }

            // Add scroll event listener
            if (conversation.value) {
                conversation.value.addEventListener('scroll', handleScroll);
            }
        });

        // Watch the messages and scroll to bottom when they change
        watch(
            () => chatStore.messages,
            () => {
                scrollToBottom();
            },
            { immediate: true }
        );

        // Watch the route parameter and fetch chats when it changes
        watch(
            () => route.params.id,
            (newId) => {
                if (newId) {
                    chatStore.fetchUser(newId);
                    id.value = newId;
                }
            },
            { immediate: true }
        );

        const send = () => {
            chatStore.sendMessage(id.value);
        };

        const formatDate = (date) => {
            return new Date(date).toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });
        };

        return { chatStore, send, formatDate, id, conversation, showScrollButton, scrollToBottom };
    }
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style scoped>
section#content form>* {
    gap: unset;
}

section#content .main-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

section#content .main-content .chat-wrapper {
    margin: 0;
    flex: 1;
    display: flex;
    overflow: hidden;
}

.user {
    font-size: 14px;
    padding: 10px 40px;
    border-bottom: 1px solid var(--secondary-light-grey);
}

.user.active {
    background-color: var(--secondary-light-grey);
}

.user:hover {
    background-color: var(--secondary-light-grey);
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 15.5px;
}

.user .message {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-list {
    height: 100%;
    max-height: 100%;
    width: 250px;
    overflow-y: scroll;
    border-right: 1px solid var(--secondary-dark-grey);
}

.chat-detail {
    padding: 0px 20px 20px 20px;
    flex: 1;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-detail .no-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.chat-detail .user {
    padding: unset;
    padding-bottom: 10px;
}

.chat-detail .user:hover {
    background-color: unset;
}

.conversation {
    margin-top: 10px;
    height: 100%;
    overflow-y: scroll;
}

.conversation .my-message {
    margin-left: auto;
    width: fit-content;
    text-align: right;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    display: flex;
    justify-content: right;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
}

.conversation .opp-message {
    margin-right: auto;
    width: fit-content;
    text-align: left;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    display: flex;
    justify-content: right;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
}

.conversation .time {
    font-style: italic;
    font-size: 12px;
}

.scroll-button {
    position: fixed;
    bottom: 20%;
    right: 50%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    /* Adjust font size for the icon */
    padding: 0;
    /* Remove padding */
}
</style>
@/stores/ChatStore