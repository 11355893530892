<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Report</h1>
                <p>Dashboard > Report</p>
            </div>

            <v-row>
                <v-col cols="12">
                    <v-list>
                        <v-list-item v-for="report in reports.reports" class="notification-item pa-5">
                            <v-list-item-content>
                                <Report :reportImageId="report.uuid"></Report>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Report from '@/components/Report.vue';
import { httpGet } from '@/services/http';
import { toast } from 'vue3-toastify';

export default {
    name: 'ReportView',
    components: {
        TopNav,
        Report
    },
    data() {
        return {
            id: this.$route.params.id,
            reports: []
        };
    },
    created() {
        this.fetchReport()
    },
    methods: {
        fetchReport() {
            httpGet('/api/v1/travel_agents/jobs/reports/' + this.id)
                .then(response => {
                    this.reports = response.data.data
                    console.log(this.reports)
                })
                .catch(error => {
                    toast.error('Error fetching notifications');
                });
        },
    },
};
</script>

<style scoped>
.notification-item {
    background-color: #ffffff;
    /* Change background color to white for a cleaner look */
    border-radius: 12px;
    /* Increased border-radius for a more rounded appearance */
    margin-bottom: 20px;
    /* Increased padding for more white space inside the notification */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Added subtle shadow for a lifting effect */
}

.notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Added bottom margin for spacing between title and message */
}

.notification-header .v-list-item-title {
    margin-left: 15px;
    /* Slightly increased margin-left for more space between icon and title */
    font-weight: 500;
    /* Slightly bolder font for the title */
}

.notification-time {
    font-size: 0.875rem;
    color: gray;
    margin-left: auto;
    /* Moved time to the right */
}
</style>
