<template>
    <section id="content">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Pricing</h1>
                <p>Dashboard > Pricing</p>
            </div>

            <div class="pricing-container">
                <div class="pricing-info">
                    <h1>Get Access To <span class="highlight">Travel Agent Portal</span></h1>
                    <h2>For Just $270/month</h2>
                    <p class="mt-4">The travel agent portal has been instrumental in helping our clients scale their
                        travel agent organizations. Unlock a world of possibilities and streamline your operations with
                        our
                        comprehensive suite of tools.</p>
                    <div class="add-ons mt-4">
                        <h3>Add-ons</h3>
                        <div class="add-on-item">
                            <span class="add-on-name">Driver</span>
                            <v-btn variant="outlined" class="add-on-price" readonly>
                                $21/month each
                            </v-btn>
                        </div>
                        <div class="add-on-item">
                            <span class="add-on-name">Concurrent Login</span>
                            <v-btn variant="outlined" class="add-on-price" readonly>
                                $5/month each
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div class="pricing-details">
                    <h2>Portal Features</h2>
                    <ul>
                        <li>Access to the portal 24/7</li>
                        <li>Tracking your fleet drivers and duty drivers</li>
                        <li>Attendance taking</li>
                        <li>Access to priority job feature</li>
                        <li>Create Itineraries of your guests</li>
                        <li>Track the progress</li>
                        <li>1 Fleet Driver (Add-ons available)</li>
                        <li>1 Concurrent Login (Add-ons available)</li>
                        <li>Add Many More...</li>
                    </ul>
                    <v-btn color="primary" class="mt-5" @click="createStripeCheckoutSession">
                        Sign Up for 7 Days Free
                    </v-btn>
                    <p class="mt-3">*You will only be charged after the trial has ended</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { httpPost } from "@/services/http";
import { toast } from "vue3-toastify";
import { useAuthStore } from '@/stores/AuthStore'
import { mapState, mapActions } from 'pinia';

export default {
    name: 'SubscriptionPricingView',
    components: {
        TopNav
    },
    methods: {
        async createStripeCheckoutSession(formData) {
            let id = toast.loading("Creating checkout session...");
            try {
                const response = await httpPost('/api/v1/travel_agents/subscriptions/purchase', formData, id);
                if (response.data && response.data.url) {
                    toast.update(id, {
                        render: "Redirecting to payment...",
                        type: "info",
                        isLoading: false,
                        autoClose: 2000,
                    });
                    window.location.href = response.data.url;
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                console.error('Error creating Stripe Checkout session:', error);
                toast.update(id, {
                    render: 'Failed to create checkout session. Please try again.',
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                });
            }
        },
        ...mapActions(useAuthStore, ['getProfile'])
    },
    mounted() {
        this.getProfile();
    }
};
</script>

<style scoped>
.pricing-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2rem;
    background-color: var(--secondary-white);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.pricing-info,
.pricing-details {
    padding: 2rem;
    width: 50%;
}

.pricing-info {
    background-color: var(--primary-blue);
    color: var(--secondary-white);
}

.pricing-info h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.pricing-info h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.add-ons {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius);
    padding: 1.5rem;
    margin-top: 2rem;
}

.add-ons h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.add-on-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.add-on-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.add-on-name {
    font-size: 1.1rem;
}

.highlight {
    font-weight: bold;
}

.pricing-details h2 {
    font-size: 2rem;
    color: var(--primary-blue);
    margin-bottom: 1rem;
}

.pricing-details ul {
    list-style-type: none;
    padding: 0;
}

.pricing-details li {
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
    color: var(--text-color);
}

.pricing-details li::before {
    content: "✓";
    color: var(--primary-blue);
    font-weight: bold;
    margin-right: 0.5rem;
}

@media screen and (max-width: 660px) {
    .pricing-container {
        flex-direction: column;
    }

    .pricing-info,
    .pricing-details {
        width: 100%;
    }
}
</style>