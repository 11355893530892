<template>
    <nav class="side-nav">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.427" height="27.292" viewBox="0 0 30.427 27.292"
            class="nav-mobile-nav" @click="toggleNav()">
            <line id="Line_4" data-name="Line 4" x2="29.798" y2="4.365" transform="translate(5.203 2.397) rotate(40)"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3"></line>
            <line id="Line_5" data-name="Line 5" y1="4.365" x2="29.798" transform="translate(28.03 5.741) rotate(140)"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3"></line>
        </svg>
        <router-link to="/" class="logo">
            <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
            <img src="@/assets/logo/Main_2.png" alt="logo" class="simple">
        </router-link>
        <ul>
            <div class="category">
                <p>GENERAL</p>
            </div>
            <router-link to="/" :class="{ 'active': $route.path === '/' }" title="Dashboard">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.501" height="21.525"
                            viewBox="0 0 20.501 21.525">
                            <path id="Path_487" data-name="Path 487"
                                d="M14.249,2a.564.564,0,0,0-.359.154L4.307,10.815A1.025,1.025,0,0,0,4,11.584V23.525h7.687V15.274h5.125v8.251H24.5V11.584a1.025,1.025,0,0,0-.307-.769L14.608,2.154A.564.564,0,0,0,14.249,2Z"
                                transform="translate(-3.999 -2)" fill="currentColor" />
                        </svg>

                        <span>Dashboard</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/calendar" :class="{ 'active': $route.path === '/calendar' }" title="Calendar">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.504" height="23.433"
                            viewBox="0 0 20.504 23.433">
                            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt"
                                d="M0,21.236a2.2,2.2,0,0,0,2.2,2.2h16.11a2.2,2.2,0,0,0,2.2-2.2V8.787H0Zm14.646-8.971a.551.551,0,0,1,.549-.549h1.831a.551.551,0,0,1,.549.549V14.1a.551.551,0,0,1-.549.549H15.2a.551.551,0,0,1-.549-.549Zm0,5.858a.551.551,0,0,1,.549-.549h1.831a.551.551,0,0,1,.549.549v1.831a.551.551,0,0,1-.549.549H15.2a.551.551,0,0,1-.549-.549ZM8.787,12.266a.551.551,0,0,1,.549-.549h1.831a.551.551,0,0,1,.549.549V14.1a.551.551,0,0,1-.549.549H9.337a.551.551,0,0,1-.549-.549Zm0,5.858a.551.551,0,0,1,.549-.549h1.831a.551.551,0,0,1,.549.549v1.831a.551.551,0,0,1-.549.549H9.337a.551.551,0,0,1-.549-.549ZM2.929,12.266a.551.551,0,0,1,.549-.549H5.309a.551.551,0,0,1,.549.549V14.1a.551.551,0,0,1-.549.549H3.478a.551.551,0,0,1-.549-.549Zm0,5.858a.551.551,0,0,1,.549-.549H5.309a.551.551,0,0,1,.549.549v1.831a.551.551,0,0,1-.549.549H3.478a.551.551,0,0,1-.549-.549Zm15.378-15.2h-2.2V.732A.734.734,0,0,0,15.378,0H13.914a.734.734,0,0,0-.732.732v2.2H7.323V.732A.734.734,0,0,0,6.591,0H5.126a.734.734,0,0,0-.732.732v2.2H2.2A2.2,2.2,0,0,0,0,5.126v2.2H20.5v-2.2A2.2,2.2,0,0,0,18.307,2.929Z"
                                fill="currentColor" />
                        </svg>


                        <span>Calendar</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/itineraries" :class="{ 'active': $route.path === '/itineraries' }" title="Itineraries">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="19.475" viewBox="0 0 20.5 19.475">
                            <path id="Icon_material-work" data-name="Icon material-work"
                                d="M21.45,7.1h-4.1V5.05A2.043,2.043,0,0,0,15.3,3H11.2A2.043,2.043,0,0,0,9.15,5.05V7.1H5.05A2.034,2.034,0,0,0,3.01,9.15L3,20.425a2.043,2.043,0,0,0,2.05,2.05h16.4a2.043,2.043,0,0,0,2.05-2.05V9.15A2.043,2.043,0,0,0,21.45,7.1Zm-6.15,0H11.2V5.05h4.1Z"
                                transform="translate(-3 -3)" fill="currentColor" />
                        </svg>

                        <span>Itineraries</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/requests" :class="{ 'active': $route.path === '/requests' }" title="Requests">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.072" height="19.07"
                            viewBox="0 0 19.072 19.07">
                            <g id="transfer_4_fill" transform="translate(-2.46 -2.465)">
                                <path id="Path_521" data-name="Path 521"
                                    d="M16.5,6.5A1.5,1.5,0,0,1,18,8v8.414l.974-.975a1.5,1.5,0,1,1,2.122,2.122L17.556,21.1a1.5,1.5,0,0,1-2.121,0L11.9,17.561a1.5,1.5,0,0,1,2.122-2.122l.975.975V8a1.5,1.5,0,0,1,1.5-1.5ZM8.556,2.9l3.536,3.535A1.5,1.5,0,1,1,9.97,8.561L9,7.586V16a1.5,1.5,0,0,1-3,0V7.586l-.975.975A1.5,1.5,0,1,1,2.9,6.439L6.435,2.9a1.5,1.5,0,0,1,2.121,0Z"
                                    fill="currentColor" />
                            </g>
                        </svg>

                        <span>Requests</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/priority/jobs" :class="{ 'active': $route.path === '/priority/jobs' }"
                title="Priority Jobs">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4.346" height="19.557"
                            viewBox="0 0 4.346 19.557">
                            <g id="Group_1349" data-name="Group 1349" transform="translate(-170 -490)">
                                <path id="Path_511" data-name="Path 511"
                                    d="M19.346,27.673A2.173,2.173,0,1,1,17.173,25.5,2.173,2.173,0,0,1,19.346,27.673Z"
                                    transform="translate(155 479.711)" fill="currentColor" />
                                <path id="Path_512" data-name="Path 512"
                                    d="M17.173,4.5A2.179,2.179,0,0,0,15,6.673v8.692a2.173,2.173,0,1,0,4.346,0V6.673A2.179,2.179,0,0,0,17.173,4.5Z"
                                    transform="translate(155 485.5)" fill="currentColor" />
                            </g>
                        </svg>

                        <span>Priority Jobs</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/dtc" :class="{ 'active': $route.path === '/dtc' }" title="Drivers, Tour Guides & Cons">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25.064" height="16.113"
                            viewBox="0 0 25.064 16.113">
                            <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people"
                                d="M19.339,14.589a3.357,3.357,0,1,0-3.418-3.357A3.4,3.4,0,0,0,19.339,14.589Zm-9.114,0a3.357,3.357,0,1,0-3.418-3.357A3.4,3.4,0,0,0,10.225,14.589Zm0,2.462c-2.677,0-7.975,1.287-7.975,3.916v3.021H18.363V20.966C18.363,18.337,12.9,17.05,10.225,17.05Zm9.114.616a6.08,6.08,0,0,0-.976.056,3.336,3.336,0,0,1,1.79,3.245v3.021h7.161V20.966C27.314,18.337,22.017,17.666,19.339,17.666Z"
                                transform="translate(-2.25 -7.875)" fill="currentColor" />
                        </svg>

                        <span>Drivers, Tour Guides & Cons</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/billings" :class="{ 'active': $route.path === '/billings' }" title="Billings">
                <li>
                    <div class="nav-content">
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                            <title>currency_dollar_2_fill</title>
                            <g id="currency_dollar_2_fill" fill='none' fill-rule='nonzero'>
                                <path d='M0 0h24v24H0z' />
                                <path fill='currentColor'
                                    d='M13.5 4a1.5 1.5 0 0 0-3 0v.5h-1a4.5 4.5 0 0 0 0 9h5a1.5 1.5 0 0 1 0 3H7a1.5 1.5 0 0 0 0 3h3.5v.5a1.5 1.5 0 0 0 3 0v-.5h1a4.5 4.5 0 1 0 0-9h-5a1.5 1.5 0 1 1 0-3H17a1.5 1.5 0 0 0 0-3h-3.5z' />
                            </g>
                        </svg>

                        <span>Billings</span>
                    </div>
                </li>
            </router-link>
            <div class="category">
                <p>SHORTCUTS</p>
            </div>
            <router-link to="/jobs/add" :class="{ 'active': $route.path === '/jobs/add' }" title="Add Itinerary">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.541" height="21.541"
                            viewBox="0 0 21.541 21.541">
                            <path id="Icon_ionic-ios-add-circle" data-name="Icon ionic-ios-add-circle"
                                d="M14.145,3.375a10.77,10.77,0,1,0,10.77,10.77A10.769,10.769,0,0,0,14.145,3.375Zm4.686,11.6H14.974v3.858a.828.828,0,0,1-1.657,0V14.974H9.459a.828.828,0,0,1,0-1.657h3.858V9.459a.828.828,0,0,1,1.657,0v3.858h3.858a.828.828,0,0,1,0,1.657Z"
                                transform="translate(-3.375 -3.375)" fill="currentColor" />
                        </svg>

                        <span>Add Itinerary</span>
                    </div>
                </li>
            </router-link>
            <router-link to="/jobs/priority/add" :class="{ 'active': $route.path === '/jobs/priority/add' }"
                title="Add Priority Job">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.541" height="21.541"
                            viewBox="0 0 21.541 21.541">
                            <path id="Icon_ionic-ios-add-circle" data-name="Icon ionic-ios-add-circle"
                                d="M14.145,3.375a10.77,10.77,0,1,0,10.77,10.77A10.769,10.769,0,0,0,14.145,3.375Zm4.686,11.6H14.974v3.858a.828.828,0,0,1-1.657,0V14.974H9.459a.828.828,0,0,1,0-1.657h3.858V9.459a.828.828,0,0,1,1.657,0v3.858h3.858a.828.828,0,0,1,0,1.657Z"
                                transform="translate(-3.375 -3.375)" fill="currentColor" />
                        </svg>

                        <span>Add Priority Job</span>
                    </div>
                </li>
            </router-link>
            <div class="category">
                <p>ACCOUNT</p>
            </div>
            <router-link to="/profile" :class="{ 'active': $route.path === '/profile' }" title="Profile">
                <li>
                    <div class="nav-content">
                        <v-avatar size="20" class="avatar-container">
                            <Image :imagePath="this.profile.image_path" v-if="this.profile.image_path"></Image>
                        </v-avatar>

                        <span>{{ this.profile.last_name }}</span>
                    </div>
                </li>
            </router-link>
            <a href="" @click.prevent="logout()" title="Logout">
                <li>
                    <div class="nav-content">
                        <svg fill="currentColor" height="18.5px" width="18.5px" version="1.1" id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 384.971 384.971" xml:space="preserve" stroke="currentColor"
                            stroke-width="18.774565">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g>
                                    <g id="Sign_Out">
                                        <path
                                            d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z">
                                        </path>
                                        <path
                                            d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <span>Logout</span>
                    </div>
                </li>
            </a>
            <a class="side-bar-toggle" @click.prevent="toggleSideBar()" title="Toggle Sidebar">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.488" height="16.839"
                            viewBox="0 0 18.488 16.839">
                            <g id="Icon_akar-sidebar-right" data-name="Icon akar-sidebar-right"
                                transform="translate(-2 -3.5)">
                                <path id="Path_520" data-name="Path 520"
                                    d="M4.649,4.5H17.839a1.649,1.649,0,0,1,1.649,1.649V17.691a1.649,1.649,0,0,1-1.649,1.649H4.649A1.649,1.649,0,0,1,3,17.691V6.149A1.649,1.649,0,0,1,4.649,4.5Z"
                                    fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" />
                                <path id="Path_521" data-name="Path 521" d="M22.5,4.5V19.339"
                                    transform="translate(-8.783)" fill="none" stroke="currentColor" stroke-width="2" />
                            </g>
                        </svg>


                        <span>Toggle Sidebar</span>
                    </div>
                </li>
            </a>
        </ul>
    </nav>
</template>

<script>
import Image from '@/components/Image.vue';
import nav from '@/assets/js/nav'
import { mapStores, mapState, mapActions } from 'pinia'
import { useSideBarStore } from '@/stores/SideBarStore'
import { useAuthStore } from '@/stores/AuthStore'
import $ from 'jquery'

export default {
    name: 'Nav',
    components: {
        Image,
    },
    computed: {
        ...mapStores(useSideBarStore, useAuthStore),
        ...mapState(useAuthStore, ['profile']),
    },
    methods: {
        toggleNav() {
            nav.toggleNav();
        },
        handleNavToggle() {
            console.log("BELOOW")
            if (window.innerWidth <= 850) {
                nav.toggleNav();
            }
        },
        ...mapActions(useSideBarStore, ['toggleSideBar']),
        ...mapActions(useAuthStore, ['logout']),
    },
    mounted() {
        $('a').on('click', this.handleNavToggle);
    },
    beforeDestroy() {
        $('a').off('click', this.handleNavToggle);
    }
}
</script>

<style scoped>
@media screen and (max-width: 1250px) {
    .side-bar-toggle {
        display: none;
    }
}

.v-avatar {
    border: 1px solid var(--secondary-dark-grey);
}
</style>