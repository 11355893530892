<template>
    <section id="content" class="result-page">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Subscription Result</h1>
                <p>Dashboard > Result</p>
            </div>

            <div class="result-content">
                <h2 class="welcome-title">Welcome to Rectrix Link!</h2>
                <p class="welcome-message">You now have access to the features that come with being a Rectrix Link
                    Travel Agent Member!</p>

                <PaymentResult :isSuccess="true" :message="'Your transaction was successful!'" />

                <p class="redirect-message">You will be redirected to the dashboard in {{ timer }} seconds.</p>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import PaymentResult from '@/components/PaymentResult.vue';

export default {
    name: 'ResultView',
    components: {
        TopNav,
        PaymentResult
    },
    data() {
        return {
            timer: 30
        }
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        startTimer() {
            const interval = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                    clearInterval(interval);
                    this.redirectToDashboard();
                }
            }, 1000);
        },
        redirectToDashboard() {
            // Assuming you're using vue-router
            this.$router.push('/');
        }
    }
}
</script>

<style src="@/assets/css/index.css" scoped></style>
<style scoped>
.result-content {
    text-align: center;
    margin: 0 auto;
}

.welcome-title {
    font-size: 2.5em;
    color: var(--primary-blue);
    font-weight: bold;
    animation: fadeIn 1s ease-in-out;
}

.welcome-message {
    margin-top: 10px;
    color: #34495e;
    line-height: 1.6;
    animation: slideIn 1s ease-in-out;
}

.redirect-message {
    font-size: 1em;
    color: #7f8c8d;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>