<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Priority Jobs</h1>
                <p>Dashboard > Priority Jobs</p>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('active')"
                                :class="{ 'button-1': currentFilter === 'active' }">Active</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('cancelled')"
                                :class="{ 'button-1': currentFilter === 'cancelled' }">Cancelled</a>
                        </li>
                    </ul>
                </div>

                <div class="table-container">
                    <v-data-table v-if="data && data.length" :headers="headers" :items="data" :items-per-page="-1"
                        disable-pagination hide-default-footer>
                        <template v-slot:[`item.index`]="{ index }">
                            <span>{{ index + 1 }}.</span>
                        </template>
                        <template v-slot:[`item.tourist.first_name`]="{ item }">
                            <div class="user">
                                <div class="image-container">
                                    <Image :imagePath="item.tourist.image_path"></Image>
                                </div>
                                <p>{{ (item.tourist.first_name ?? '') + ' ' + item.tourist.last_name }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.tourist.role.role_name`]="{ item }">
                            <span>{{ item.tourist.role.role_name }} {{ item.tourist && item.tourist.tourist &&
                                    item.tourist.tourist.uuid
                                    ? (item.tourist.tourist.uuid === this.profile.id ? '(Own Fleet)' : '')
                                    : '' }}
                            </span>
                        </template>
                        <template v-slot:[`item.contact_number`]="{ item }">
                            <span>+{{ item.tourist.contact_numbers.find((a) => a.primary ===
                                    1).country_code.country_code }}{{ item.tourist.contact_numbers.find((a) => a.primary ===
                                    1).contact_number }}</span>
                        </template>
                        <template v-slot:[`item.first_pickup`]="{ item }">
                            <span>{{ formatDate(item.first_pickup) }}</span>
                        </template>
                        <template v-slot:[`item.last_pickup`]="{ item }">
                            <span>{{ formatDate(item.last_pickup) }}</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ this.$moment((item.created_at)).format('DD MMM yyyy') }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <a href="" class="button-3"
                                @click.prevent="this.$router.push('/jobs/' + item.uuid)">View</a>
                        </template>
                    </v-data-table>
                    <table class="no-data" v-else>
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'PriorityJobView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            headers: [
                { title: 'No.', value: 'index', sortable: false },
                { title: 'Guest Name', value: 'tourist.first_name', sortable: true },
                { title: 'Contact Number', value: 'contact_number', sortable: false },
                { title: 'First Pick Up Time', value: 'first_pickup', sortable: true },
                { title: 'Last Pick Up Time', value: 'last_pickup', sortable: true },
                { title: 'Requested On', value: 'created_at', sortable: true },
                { title: 'Action', value: 'action', sortable: false },
            ],
            orgData: [],
            data: [],
            currentFilter: 'active',
            subscription: null,
        };
    },
    created() {
        indexedDBService.getJobsData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData = liveQuery(async () => {
                const allJobs = await db.jobs.orderBy('created_at').reverse().toArray();
                const jobs = allJobs.filter(job => job.priority !== null);
                const users = await db.users.toArray();
                const transfers = await db.transfers.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                const jobsWithDetails = jobs.map(job => {
                    const user = userMap.get(job.user_uuid) || null;
                    const tourist = userMap.get(job.tourist_uuid) || null;
                    const jobTransfers = transfers.filter(transfer => transfer.job_uuid === job.uuid);

                    const pickupTimes = jobTransfers
                        .map(transfer => new Date(transfer.pick_up_time))
                        .filter(time => !isNaN(time.getTime()))
                        .sort((a, b) => a - b);

                    const firstPickup = pickupTimes.length ? pickupTimes[0] : null;
                    const lastPickup = pickupTimes.length ? pickupTimes[pickupTimes.length - 1] : null;

                    return {
                        ...job,
                        user: user,
                        tourist: tourist,
                        transfers: jobTransfers,
                        first_pickup: firstPickup,
                        last_pickup: lastPickup
                    };
                });

                return jobsWithDetails;
            });

            this.subscription = observableData.subscribe((result) => {
                this.orgData = result;
                this.changeFilter(this.currentFilter);
            });
        },
        changeFilter(input) {
            this.currentFilter = input;
            if (this.currentFilter === 'active') {
                this.data = this.orgData.filter((a) => a.deleted_at === null);
            } else {
                this.data = this.orgData.filter((a) => a.deleted_at !== null);
            }
        },
        formatDate(date) {
            return date ? this.$moment(date).format('DD MMM yyyy HH:mm') : 'N/A';
        }
    },
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
