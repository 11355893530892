import db from '@/databases/indexedDB';
import { httpGet } from '@/services/http'; // Adjust the import based on your project structure

async function getTransferTypesData() {
    try {
        // Retrieve the most recent update timestamp from the 'requests' store
        const mostRecentUpdate = await db.transfer_types.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/transfers/types${queryParams}`);

        console.log('Max. requests: ' + response.data.data.length)

        if (response && response.data && response.data.data) {
            const transfers = response.data.data.map(item => {
                const { location, ...transfer } = item;
                return { ...transfer, location_uuid: location ? location.uuid : null };
            });

            const locations = response.data.data
                .filter(item => item.location) // Only include items with non-null locations
                .map(item => {
                    const { detail, ...location } = item.location;
                    return { ...location, detail_uuid: detail ? detail.uuid : null };
                });

            const locationDetails = response.data.data
                .filter(item => item.location && item.location.detail) // Only include items with non-null location details
                .map(item => item.location.detail);

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.transfer_types, db.locations, db.location_details], async () => {
                await db.transfer_types.bulkPut(transfers);
                await db.locations.bulkPut(locations);
                await db.location_details.bulkPut(locationDetails);
            });
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get requests data:', error);
    }
}

async function getDtcsData() {
    try {
        // Retrieve the most recent update timestamp from the 'dtc_requests' store
        const mostRecentUpdate = await db.dtc_requests.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/dtcs${queryParams}`);

        console.log('Max. dtc_requests: ' + response.data.data.length)

        if (response && response.data && response.data.data) {
            const dtcRequests = response.data.data.map(item => {
                const { requester, user, details, ...dtcRequest } = item;
                return { ...dtcRequest, requester_uuid: requester ? requester.uuid : null, user_uuid: user ? user.uuid : null };
            });

            const users = response.data.data
                .flatMap(item => [item.requester, item.user])
                .filter(user => user) // Only include non-null users
                .map(user => {
                    return user;
                });

            const dtcRequestDetails = response.data.data
                .flatMap(item => item.details.map(detail => {
                    const { transfer_type_special, ...rest } = detail;
                    return {
                        ...rest,
                        transfer_type_special_uuid: transfer_type_special.uuid,
                        dtc_request_uuid: item.uuid
                    };
                }));

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.dtc_requests, db.users, db.dtc_request_details], async () => {
                await db.dtc_requests.bulkPut(dtcRequests);
                await db.users.bulkPut(users);
                await db.dtc_request_details.bulkPut(dtcRequestDetails);
            });
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get DTC data:', error);
    }
}

async function getJobsData() {
    try {
        // Retrieve the most recent update timestamp from the 'jobs' store
        const mostRecentUpdate = await db.jobs.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/jobs${queryParams}`);

        console.log('Max. jobs: ' + response.data.data.length);

        if (response && response.data && response.data.data) {
            // Map server response to local IndexedDB schema
            const jobs = response.data.data.map(item => {
                const { job_status, user, tourist, stays, transfers, ...job } = item;
                return {
                    uuid: item.uuid,
                    ...job,
                    job_status: job_status ? job_status.uuid : null,
                    user_uuid: user ? user.uuid : null,
                    tourist_uuid: tourist ? tourist.uuid : null
                };
            });

            const users = response.data.data
                .flatMap(item => [item.user, item.tourist])
                .filter(user => user) // Only include non-null users
                .map(user => ({
                    uuid: user.uuid,
                    ...user
                }));

            const stays = response.data.data
                .flatMap(item => item.stays.map(stay => {
                    const { hotel, ...rest } = stay;
                    return {
                        uuid: stay.uuid,
                        ...rest,
                        hotel_uuid: hotel.uuid,
                        job_uuid: item.uuid
                    };
                }));

            const transfers = response.data.data
                .flatMap(item => item.transfers.map(transfer => {
                    const { transfer_type, transfer_type_special, from, to, ...rest } = transfer;
                    return {
                        uuid: transfer.uuid,
                        ...rest,
                        transfer_type_name: transfer_type.type_name,
                        transfer_type_slug: transfer_type.type_slug,
                        transfer_type_special_uuid: transfer_type_special.uuid,
                        from_uuid: from.uuid,
                        to_uuid: to.uuid,
                        job_uuid: item.uuid
                    };
                }));

            const locations = response.data.data
                .flatMap(item => item.transfers.flatMap(transfer => [transfer.from, transfer.to]))
                .map(location => {
                    const { detail, ...rest } = location;
                    return { ...rest, detail_uuid: detail ? detail.uuid : null };
                })
                .filter((location, index, self) =>
                    index === self.findIndex(l => l.uuid === location.uuid)
                );

            const locationDetails = response.data.data
                .flatMap(item => item.transfers.flatMap(transfer => [transfer.from, transfer.to]))
                .filter(location => location.detail)
                .map(location => location.detail)
                .filter((detail, index, self) =>
                    index === self.findIndex(d => d.uuid === detail.uuid)
                );

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.jobs, db.users, db.stays, db.transfers, db.locations, db.location_details], async () => {
                await db.jobs.bulkPut(jobs);
                await db.users.bulkPut(users);
                await db.stays.bulkPut(stays);
                await db.transfers.bulkPut(transfers);
                await db.locations.bulkPut(locations);
                await db.location_details.bulkPut(locationDetails);
            });

            console.log('Data successfully updated in IndexedDB');
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get and store data:', error);
    }
}

async function getAssignedJobRequestsData() {
    try {
        // Retrieve the most recent update timestamp from the 'assigned_job_requests' store
        const mostRecentUpdate = await db.assigned_job_requests.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/jobs/assigns${queryParams}`);

        console.log('Max. assigned_job_requests: ' + response.data.data.length)

        if (response && response.data && response.data.data) {
            const assignedJobRequests = response.data.data.map(item => {
                const { requester, user, details, transfer_type_special_request, ...assignedJobRequest } = item;
                return { ...assignedJobRequest, requester_uuid: requester ? requester.uuid : null, user_uuid: user ? user.uuid : null };
            });

            const users = response.data.data
                .flatMap(item => [item.requester, item.user])
                .filter(user => user) // Only include non-null users
                .map(user => {
                    return user;
                });

            const assignedJobRequestDetails = response.data.data
                .flatMap(item => item.details.map(detail => {
                    const { transfer, ...rest } = detail;
                    return {
                        ...rest,
                        transfer_uuid: transfer.uuid,
                        assigned_job_request_uuid: item.uuid
                    };
                }));

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.assigned_job_requests, db.users, db.assigned_job_request_details], async () => {
                await db.assigned_job_requests.bulkPut(assignedJobRequests);
                await db.users.bulkPut(users);
                await db.assigned_job_request_details.bulkPut(assignedJobRequestDetails);
            });
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get DTC data:', error);
    }
}


const deleteDatabase = () => {
    const request = indexedDB.deleteDatabase('rectrix-link-travel-agent');

    request.onsuccess = function () {
        console.log(`Database deleted successfully`);
    };

    request.onerror = function () {
        console.error(`Error deleting database`);
    };

    request.onblocked = function () {
        console.warn(`Delete operation blocked for database`);
    };
};


export const indexedDBService = {
    getTransferTypesData,
    getDtcsData,
    getJobsData,
    getAssignedJobRequestsData,
    deleteDatabase
};