<template>
    <div class="result-container">
        <div class="result-card">
            <div class="status-icon" :class="{ 'success': isSuccess, 'failure': !isSuccess }">
                <v-icon :color="isSuccess ? 'green' : 'red'">
                    {{ isSuccess ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                </v-icon>
            </div>
            <h2>{{ isSuccess ? 'Success!' : 'Failure' }}</h2>
            <p class="message">{{ message }}</p>

            <v-btn color="primary" @click="handleAction">
                {{ actionButtonText }}
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentResult',
    props: {
        isSuccess: {
            type: Boolean,
            default: true
        },
        message: {
            type: String,
            required: true
        },
        actionButtonText: {
            type: String,
            default: 'Back to Dashboard'
        }
    },
    methods: {
        handleAction() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
.result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
}

.result-card {
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    width: 350px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.status-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    font-size: 30px;
}

.success {
    background-color: #E8F5E9;
}

.failure {
    background-color: #FFEBEE;
}

h2 {
    margin-bottom: 10px;
}

.message {
    color: #666;
    margin-bottom: 20px;
}
</style>