import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyATCndywdTMJkkhr52CWcYmgLHU0CLiyqg",
    authDomain: "rectrix-link.firebaseapp.com",
    databaseURL: "https://rectrix-link-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "rectrix-link",
    storageBucket: "rectrix-link.firebasestorage.app",
    messagingSenderId: "537121290459",
    appId: "1:537121290459:web:5fae7b476a8eb64c9ef4fe",
    measurementId: "G-WD29EK6WZP"
};

const app = initializeApp(firebaseConfig);

export default getMessaging(app)