<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Billing Preview</h1>
                <p>Dashboard > Billings > Preview</p>
            </div>

            <div class="dtc-details">
                <div class="title">
                    <h3>DTC Details</h3>
                    <p>Select a driver, tour guide or contractor</p>
                </div>
                <v-form ref="form">
                    <div class="dtc">
                        <v-col cols="12" class="pa-0">
                            <v-autocomplete :items="dtc" item-title="full_name" item-value="uuid"
                                label="Select Driver/Tour Guide/Contractor" v-model="id"
                                :rules="dtcValidation"></v-autocomplete>
                        </v-col>
                        <v-row>
                            <v-col cols="6" class="start-date">
                                <v-text-field v-model="startDate" name="start-date" type="date" id="start-date"
                                    ref="startDate" class="form-control" label="Start Date"
                                    :rules="startDateValidation"></v-text-field>
                            </v-col>
                            <v-col cols="6" class="end-date">
                                <v-text-field v-model="endDate" name="end-date" type="date" id="end-date"
                                    class="form-control" label="End Date" :rules="endDateValidation"></v-text-field>
                            </v-col>
                        </v-row>
                        <div class="buttons">
                            <v-btn class="button-1 w-100" @click="preview" :loading="isLoading">Preview</v-btn>
                        </div>
                    </div>
                </v-form>
            </div>

            <div class="billing-preview table-container">
                <v-data-table v-if="billingPreviewData && billingPreviewData.length" :headers="headers"
                    :items="billingPreviewData" :items-per-page="-1" disable-pagination hide-default-footer>
                    <template v-slot:[`item.index`]="{ index }">
                        <span>{{ index + 1 }}.</span>
                    </template>
                    <template v-slot:[`item.pick_up_time`]="{ item }">
                        <span>{{ formatDateTimeFull(item.pick_up_time) }}</span>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <span>${{ item.price.toFixed(2) }}</span>
                    </template>
                    <template v-slot:body.append>
                        <tr>
                            <td colspan="7" class="text-right"><strong>Total</strong></td>
                            <td><strong>${{ totalPrice.toFixed(2) }}</strong></td>
                        </tr>
                    </template>
                </v-data-table>
                <table class="no-data" v-else>
                    <thead>
                        <tr>
                            <td class="td-start">
                                {{ id !== null && billingPreviewData && !isLoading ? 'Biling data not available' :
                                isLoading ? 'Loading...' : 'Select a driver, tour guide or contractor' }}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { httpGet, httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';

export default {
    name: 'BillingPreviewView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            id: null,
            dtc: [],
            billingPreviewData: null,
            headers: [
                { title: 'No.', value: 'index', sortable: false },
                { title: 'Pick Up Time', value: 'pick_up_time', sortable: true },
                { title: 'Guest Name', value: 'guest_name', sortable: true },
                { title: 'Transfer Type', value: 'transfer_type', sortable: true },
                { title: 'Slug', value: 'transfer_type_special_slug', sortable: true },
                { title: 'From', value: 'from', sortable: true },
                { title: 'To', value: 'to', sortable: true },
                { title: 'Price', value: 'price', sortable: true },
            ],
            totalPrice: 0,
            startDate: '',
            endDate: '',
            dtcValidation: [
                (v) => !!v || "User is required",
            ],
            startDateValidation: [
                (v) => !!v || "Start date is required",
                (v) => {
                    if (!this.endDate || !v) return true;
                    return (
                        new Date(v) <= new Date(this.endDate) ||
                        "Start date must be before or the same as end date"
                    );
                },
            ],
            endDateValidation: [
                (v) => !!v || "End date is required",
                (v) => {
                    if (!this.startDate || !v) return true;
                    return (
                        new Date(v) >= new Date(this.startDate) ||
                        "Start date must be before or the same as end date"
                    );
                },
            ],
            isLoading: false,
        };
    },
    computed: {
        totalPrice() {
            return this.billingPreviewData.reduce((sum, billing) => sum + billing.price, 0);
        }
    },
    created() {
        this.getUsers();
    },
    watch: {
        "startDate": function (newVal, oldVal) {
            // When the start date is set and the end date is empty, autofill the end date
            if (newVal && !this.endDate) {
                this.endDate = newVal; // Set the end date to the same as start date
            }
        },
        "endDate": function (newVal) {
            this.$refs.startDate.validate(); // Manually trigger the validation for startDate
        }
    },
    methods: {
        getUsers() {
            httpGet('/api/v1/travel_agents/billings/users')
                .then(response => {
                    let userFilteredList = []
                    let users = response.data.data
                    users.forEach(user => {
                        userFilteredList.push(user.user)
                    });
                    console.log('users', userFilteredList)
                    this.dtc = userFilteredList
                    this.dtc.forEach(a => {
                        a.full_name = a.first_name + ' ' + a.last_name + ' (' + a.role.role_name + ')';
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.dtc = [];
                });
        },
        async preview() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid) {
                let dataToSend = {
                    id: this.id,
                    start_date: this.startDate,
                    end_date: this.endDate
                }
                this.isLoading = true;
                httpPost('/api/v1/travel_agents/billings/preview', dataToSend)
                    .then(response => {
                        console.log(response.data)
                        this.billingPreviewData = response.data.data
                        this.isLoading = false;
                    })
                    .catch(error => {
                        toast.remove(id);
                    });
            }
        },
        formatDateTimeFull(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();

            // Extract hours and minutes for time formatting in 24-hour format
            const hours = d.getHours();
            const minutes = d.getMinutes();

            // Ensuring two-digit hours and minutes
            const hoursFormatted = hours < 10 ? '0' + hours : hours;
            const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;

            // Format the date and time string
            return `${day} ${month} ${year} ${hoursFormatted}:${minutesFormatted}`;
        },
    },
};
</script>
