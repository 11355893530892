<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Email Verification</h1>
                <p>Dashboard > Email Verification</p>
            </div>

            <div class="info-container container">
                <h3><v-icon class="mr-3">{{ 'mdi-email' }}</v-icon> Email Verification Required</h3>
                <p>We have sent an OTP to your email address, email verification is essential to use our services.</p>
                <p>Please enter the OTP sent to your email address:</p>
                <p v-if="profile && profile.email_address"><strong>{{ profile.email_address }}</strong></p>
                <v-otp-input v-model="otp" :loading="isLoading"></v-otp-input>
                <v-btn class="button-2" :disabled="isResendDisabled" @click="resendOtp">
                    Resend OTP
                </v-btn>
                <p v-if="error" class="error-message">{{ error }}</p>
                <p v-if="timer > 0">You can resend the OTP in {{ timer }} seconds.</p>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import { httpGet, httpPost } from "@/services/http";
import { toast } from "vue3-toastify";

export default {
    name: 'EmailVerificationView',
    components: {
        TopNav,
    },
    data() {
        return {
            otp: '',
            error: '',
            timer: 0,
            resendInterval: 180, // 3 minutes
            isLoading: false,
            timerId: null, // Timer ID to clear the timer on component destruction
        };
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
        isResendDisabled() {
            return this.timer > 0;
        }
    },
    watch: {
        otp(newValue) {
            if (newValue.length === 6) {
                this.verifyOtp();
            }
        }
    },
    created() {
        if (this.profile.email_verified_at === null) {
            this.sendOtp();
        }
    },
    beforeDestroy() {
        // Clear the timer when the component is destroyed
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    },
    methods: {
        sendOtp() {
            httpGet('/api/v1/travel_agents/users/otps/send')
                .then(response => {
                    this.startTimer();
                })
                .catch(() => {
                    console.error("Failed to send OTP. Please try again later.");
                });
        },
        startTimer() {
            this.timer = this.resendInterval;
            this.countdown();
        },
        countdown() {
            if (this.timer > 0) {
                this.timerId = setTimeout(() => {
                    this.timer--;
                    this.countdown();
                }, 1000);
            }
        },
        verifyOtp() {
            if (this.otp.length !== 6) {
                this.error = 'Please enter a valid 6-digit OTP.';
                return;
            }

            let id = toast.loading("Please wait...");
            this.isLoading = true;
            httpPost('/api/v1/travel_agents/users/otps/verify', { otp: this.otp })
                .then(async response => {
                    toast.update(id, {
                        render: response.data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                    });
                    this.getProfile()
                        .then(result => {
                            this.isLoading = false;
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                })
                .catch((error) => {
                    toast.update(id, {
                        render: error.response.data.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                    });
                    this.isLoading = false;
                    this.otp = '';
                    console.log(error);
                });
        },
        resendOtp() {
            if (this.isResendDisabled) return;

            this.sendOtp();
        },
        ...mapActions(useAuthStore, ['getProfile']),
    }
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.info-container h3 {
    color: var(--primary-blue);
}
</style>
