<template>
    <section id="content">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Oops</h1>
                <p>An expected error occurred</p>
            </div>

            <div class="not-found">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>Oops! The page you're looking for doesn't exist.</p>
                <router-link to="/" class="home-button button-1">Go To Dashboard</router-link>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';

export default {
    name: 'NotFoundView',
    components: {
        TopNav
    }
};
</script>

<style scoped>
.not-found {
    text-align: center;
    padding: 2rem;
    background-color: var(--secondary-white);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.not-found h1 {
    font-size: 6rem;
    color: var(--primary-blue);
    margin-bottom: 0;
}

h2 {
    font-size: 2rem;
    color: #343a40;
    margin-top: 0;
}

.not-found p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 2rem;
}

.home-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}
</style>