import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import MapView from '@/views/MapView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import ForgetPasswordView from '@/views/ForgetPasswordView.vue'
import ConcurrentLoginView from '@/views/ConcurrentLoginView.vue'
import AddJobView from '@/views/AddJobView.vue'
import RequestsView from '@/views/RequestsView.vue'
import RequestDetailView from '@/views/RequestDetailView.vue'
import CalendarView from '@/views/CalendarView.vue'
import DTCView from '@/views/DTCView.vue'
import DTCDetailView from '@/views/DTCDetailView.vue'
import DTCAddView from '@/views/DTCAddView.vue'
import PriorityJobView from '@/views/PriorityJobView.vue'
import AddJobPriorityView from '@/views/AddJobPriorityView.vue'
import ProfileView from '@/views/ProfileView.vue'
import { useLoadingStore } from '@/stores/LoadingStore'
import { useAuthStore } from '@/stores/AuthStore'
import { useWebSocketStore } from '@/stores/WebsocketStore';
import JobDetailView from '@/views/JobDetailView.vue'
import ChatsView from '@/views/ChatsView.vue'
import ChatDetailView from '@/views/ChatDetailView.vue'
import NotificationView from '@/views/NotificationView.vue'
import SearchView from '@/views/SearchView.vue'
import ReportView from '@/views/ReportView.vue'
import $ from 'jquery'
import ItinerariesView from '@/views/ItinerariesView.vue'
import EmailVerificationView from '@/views/EmailVerificationView.vue'
import ProfileGeneralEditView from '@/views/ProfileGeneralEditView.vue'
import HandleThirdPartyView from '@/views/HandleThirdPartyView.vue'
import DTCFleetAddView from '@/views/DTCFleetAddView.vue'
import DTCFleetEditView from '@/views/DTCFleetEditView.vue'
import BillingsView from '@/views/BillingsView.vue'
import SubmitDocumentView from '@/views/SubmitDocumentView.vue'
import AccountInactiveView from '@/views/AccountInactiveView.vue'
import ItineraryTemplatesView from '@/views/ItineraryTemplatesView.vue'
import BillingPreviewView from '@/views/BillingPreviewView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import SubscriptionPricingView from '@/views/SubscriptionPricingView.vue'
import PaymentSuccessView from '@/views/PaymentSuccessView.vue'
import SubscriptionView from '@/views/SubscriptionView.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/auth',
    name: 'HandleThirdParty',
    component: HandleThirdPartyView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: EmailVerificationView
  },
  {
    path: '/submit-documents',
    name: 'SubmitDocument',
    component: SubmitDocumentView
  },
  {
    path: '/account-inactive',
    name: 'AccountInactive',
    component: AccountInactiveView
  },
  {
    path: '/forgot-password',
    name: 'ForgetPassword',
    component: ForgetPasswordView,
  },
  {
    path: '/concurrent-login',
    name: 'ConcurrentLogin',
    component: ConcurrentLoginView,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/map',
    name: 'Map',
    component: MapView
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarView,
  },
  {
    path: '/chats',
    name: 'Chat',
    component: ChatsView,
  },
  {
    path: '/chats/:id',
    name: 'ChatDetail',
    component: ChatDetailView,
  },
  {
    path: '/itineraries',
    name: 'Itineraries',
    component: ItinerariesView,
  },
  {
    path: '/requests',
    name: 'Requests',
    component: RequestsView,
  },
  {
    path: '/dtc',
    name: 'DTC',
    component: DTCView,
  },
  {
    path: '/dtc/add',
    name: 'DTCAdd',
    component: DTCAddView,
  },
  {
    path: '/dtc/fleet/add',
    name: 'DTCFleetAdd',
    component: DTCFleetAddView,
  },
  {
    path: '/dtc/:id/edit',
    name: 'DTCFleetEdit',
    component: DTCFleetEditView,
  },
  {
    path: '/dtc/:id',
    name: 'DTCDetail',
    component: DTCDetailView,
  },
  {
    path: '/billings',
    name: 'Billings',
    component: BillingsView,
  },
  {
    path: '/billings/preview',
    name: 'BillingPreview',
    component: BillingPreviewView,
  },
  {
    path: '/priority/jobs',
    name: 'PriorityJob',
    component: PriorityJobView,
  },
  {
    path: '/jobs/add',
    name: 'AddJob',
    component: AddJobView
  },
  {
    path: '/jobs/priority/add',
    name: 'AddJobPriority',
    component: AddJobPriorityView
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView
  },
  {
    path: '/profile/general/edit',
    name: 'ProfileGeneralEdit',
    component: ProfileGeneralEditView
  },
  {
    path: '/profile/travel-agent/itinerary-template',
    name: 'ItineraryTemplates',
    component: ItineraryTemplatesView
  },
  {
    path: '/jobs/:id',
    name: 'JobDetail',
    component: JobDetailView
  },
  {
    path: '/requests/:id',
    name: 'RequestDetail',
    component: RequestDetailView
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchView
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: NotificationView
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: ReportView
  },
  {
    path: '/subscriptions/pricing',
    name: 'Pricing',
    component: SubscriptionPricingView
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccessView',
    component: PaymentSuccessView
  },
  {
    path: '/subscriptions',
    name: 'SubscriptionView',
    component: SubscriptionView
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundView,
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to === from) {
      return savedPosition;
    } else {
      $('nav').animate({ scrollTop: 0 }, 50);
      return { top: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  const store = useLoadingStore();
  store.setLoading(true)
  const authStore = useAuthStore();
  console.log(to);
  if (authStore.isLogin) {
    // Access the WebSocket store
    const websocketStore = useWebSocketStore();

    if (!websocketStore.connected) {
      // // Connect the WebSocket when the application starts
      websocketStore.connect();
    }

    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      if (to.path === '/payment/success') {
        next();
      } else if (authStore.profile.email_verified_at === null) {
        if (to.path !== '/email-verification') {
          next({ path: '/email-verification' });
        } else {
          next();
        }
      } else if (authStore.profile.travel_agent === null && authStore.profile.document_sent === false) {
        if (to.path !== '/submit-documents') {
          next({ path: '/submit-documents' });
        } else {
          next();
        }
      } else if (authStore.profile.status && authStore.profile.status.status_slug !== 'active') {
        if (to.path !== '/account-inactive') {
          next({ path: '/account-inactive' });
        } else {
          next();
        }
      } else if (authStore.profile.subscription === 'Inactive') {
        if (to.path !== '/subscriptions/pricing') {
          next({ path: '/subscriptions/pricing' });
        } else {
          next();
        }
      } else if ((authStore.profile.current_token_order > authStore.profile.allowed_concurrent_logins) && !localStorage.forcedAllowLogin) {
        if (to.path !== '/concurrent-login') {
          next({ path: '/concurrent-login' });
        } else {
          next();
        }
      } else {
        if (to.path === '/email-verification' || to.path === '/account-inactive' || to.path === '/submit-documents' || to.path === '/subscriptions/pricing' || to.path === '/concurrent-login') {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  } else {
    if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/register' || to.path === '/auth') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    $('nav').css('transition', '1s all');
  }, 500)
  console.log(`Changing route from ${from.path} to ${to.path}`);
  // nextTick(() => {
  //   nav.changeColors();
  // })
});


export default router
